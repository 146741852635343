


const BereichDetail=(props)=>{


let label1=props.WertBeschriftung1;
let label2=props.WertBeschriftung2;
let label3=props.WertBeschriftung3;
let label4=props.WertBeschriftung4;
let label5=props.WertBeschriftung5;
let label6=props.WertBeschriftung6;
let value1=props.description;
let value2=props.wert2;
let value3=props.wert3;
let value4=props.wert4;
let value5=props.wert5;
let value6=props.wert6;


    //const path=props.path;
    return (<div className='liste'>
                <div className='linkliste'> 
                        <div className='linktext'>{props.pos}.{props.name}</div>
                        <div className='linkbutton'>
                            <img src={require('../images/back2.svg').default} alt='zurück' />
                        </div>
                </div>
                <div className='iteams'> 
                    <p className='p1' style={{display: props.wert1exist==='-1' ? 'block' : 'none'}}>{label1}: {value1}</p>
                    <p className='p1' style={{display: props.wert2exist==='-1' ? 'block' : 'none'}}>{label2}: {value2}</p>
                    <p className='p1' style={{display: props.wert3exist==='-1' ? 'block' : 'none'}}>{label3}: {value3}</p>
                    <p className='p1' style={{display: props.wert4exist==='-1' ? 'block' : 'none'}}>{label4}: {value4}</p>
                    <p className='p1' style={{display: props.wert5exist==='-1' ? 'block' : 'none'}}>{label5}: {value5}</p>
                    <p className='p1' style={{display: props.wert6exist==='-1' ? 'block' : 'none'}}>{label6}: {value6}</p>
                </div>
         </div>);
};

export default BereichDetail;