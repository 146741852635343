


const FragenItem=(props)=>{
    let fr=Number(props.countFr);
    let gepr=Number(props.countGeprueft);
   
    if (props.countFr>props.countGeprueft || props.countGeprueft===null){

    } else {
       // console.log(fr, gepr, 'gepr')
    }
    let description;
    if (props.description){ 
     description=`(${props.description})`;
    } else {
        description=`()`;
    }
    //const path=props.path;
    //Link to='/fragedetail'
    return (

            <div className='linkliste' onClick={props.onClick}> 
           
           <div className='linktext'>
           
            {props.pos}.{props.name} {description} 
          
            </div>
            <div className='linkbutton'>
            
            <img src={require('../images/weiter.svg').default} alt='weiter' />
            </div>
           
            
            </div>
    );
};

export default FragenItem;