
import Header from '../component/Header'
import React, { useState } from 'react';
import ChecklistItem from '../component/ChecklistItem';
//import AuftragContext from '../store/auftrag-context';
import { useEffect, useContext } from 'react';
import Message from '../component/Message/Message';
//import ChecklistContext from '../store/checklist-context';
import AuthContext from '../store/auth-context';
import { useNavigate } from 'react-router-dom';
import NodataMessage from '../component/NodataMessage';

function Checklist(props) {
  const navigate = useNavigate();
  const [message, setMessage]=useState();
  const [isLoading, setIsLoading]=useState(true);
  const [httpError, setHttpError]=useState();
  const[checklistList, setChecklistList]=useState();
  const [pageName, setPageName] = useState( 
    {name: 'Checklisten', 
    link1: '/auftraege', 
    link2: '/',
    hide1: 'no',
    hide2: 'no'
  }
    );
    const authCtx = useContext(AuthContext);
   // const auftragCtx=useContext(AuftragContext);
    //const checklistCtx=useContext(ChecklistContext);
//console.log(authCtx)
    let auftragid=authCtx.auftragid;
    
    let url='https://tbsmobile.de/react-checkliste.php?id='+auftragid+'&userid='+authCtx.userid+'&ausfuehrung='+authCtx.ausfuehrungid;
  


   

useEffect(()=>{
  if (auftragid!==null) {
        getChecklist();
      }
}, [auftragid])
  
let loadedItemsfrage=[];
var jsonArray;
const addBereichlistHandler=(id)=>{
  let fkAuftragWartungsChecklisteID=id;
  let url='https://tbsmobile.de/react-bereiche2.php?id='+fkAuftragWartungsChecklisteID+'&ausfuehrungid='+authCtx.ausfuehrungid;
  let urlpumpe='https://tbsmobile.de/react-checklistepumpe.php?id='+fkAuftragWartungsChecklisteID+'&ausfuehrung='+authCtx.ausfuehrungid;

  const fetchItems=async()=>{
    setIsLoading(true);
  
  const response=await fetch(url, {


    headers: {
      'Content-Type': 'application/json'
    },
   
});


if(!response.ok){
throw new Error('Something went wrong!');
}
const responseData=await response.json();

//transform to array
const loadedItems=[];

//console.log(responseData, 'responsedata');

for (const key in responseData){
  loadedItems.push({
    id: responseData[key].lfdAuftragWartungsBereichID,
    name: responseData[key].ChecklistenBereich,
    description: responseData[key].Wert1,
    pos: responseData[key].PosNr,
    wert2:  responseData[key].Wert2,
    wert3:  responseData[key].Wert3,
    wert4:  responseData[key].Wert4,
    wert5:  responseData[key].Wert5,
    wert6:  responseData[key].Wert6,
    wert1exist: responseData[key].Wert1Exist,
    wert2exist: responseData[key].Wert2Exist,
    wert3exist: responseData[key].Wert3Exist,
    wert4exist: responseData[key].Wert4Exist,
    wert5exist: responseData[key].Wert5Exist,
    wert6exist: responseData[key].Wert6Exist,
    WertBeschriftung1: responseData[key].WertBeschriftung1,
    WertBeschriftung2: responseData[key].WertBeschriftung2,
    WertBeschriftung3: responseData[key].WertBeschriftung3,
    WertBeschriftung4: responseData[key].WertBeschriftung4,
    WertBeschriftung5: responseData[key].WertBeschriftung5,
    WertBeschriftung6: responseData[key].WertBeschriftung6,
    countFr: responseData[key].countFr,
    CountGeprueft: responseData[key].CountGeprueft,
});

}

authCtx.addBereichlist(JSON.stringify(responseData));

//setFragenList(loadedItems);
//setIsLoading(false);

//fragenlist
//list at FrageDetail.js
//const loadedFragenItems=[];

for (const key in responseData){
  //id: responseData[key].lfdAuftragWartungsBereichID,
  let bereichid=responseData[key].lfdAuftragWartungsBereichID;
  let urlfrage='https://tbsmobile.de/react-checklistefragen.php?id='+bereichid;
  const fetchfrageItems=async()=>{
  
    const responsefrage=await fetch(urlfrage, {
      headers: {
        'Content-Type': 'application/json'
      }
  });
  
  if(!responsefrage.ok){
      throw new Error('Something went wrong!');
  }
  const responseDatafrage=await responsefrage.json();
  
  for (const key in responseDatafrage){
 
var jsonArg1 = {};
jsonArg1.fkAuftragWartungsChecklisteID = fkAuftragWartungsChecklisteID;
jsonArg1.fkAuftragWartungsBereichID = bereichid;
jsonArg1.lfdAuftragWartungsChecklistenFrageID = responseDatafrage[key].lfdAuftragWartungsChecklistenFrageID;
jsonArg1.ChecklistenFrage = responseDatafrage[key].ChecklistenFrage;
jsonArg1.PosNr = responseDatafrage[key].PosNr;
jsonArg1.Wert1 = responseDatafrage[key].Wert1;
jsonArg1.Wert2 = responseDatafrage[key].Wert2;
jsonArg1.Wert3 = responseDatafrage[key].Wert3;
jsonArg1.WertBeschriftung1 = responseDatafrage[key].WertBeschriftung1;
jsonArg1.WertBeschriftung2 = responseDatafrage[key].WertBeschriftung2;
jsonArg1.WertBeschriftung3 = responseDatafrage[key].WertBeschriftung3;
loadedItemsfrage.push(jsonArg1);
jsonArray = JSON.parse(JSON.stringify(loadedItemsfrage));
authCtx.addFragenlist(JSON.stringify(jsonArray));
};
///////////////end 
}

fetchfrageItems().catch(error=>{
          setHttpError(error.message);
    });



}
//end cycle
};
//end fetch

fetchItems().catch(error=>{
//setIsLoading(false);
//setHttpError(error.message);
console.log(error.message);
});


const fetchPumpeItems=async()=>{
  setIsLoading(true);
  const responsePumpe=await fetch(urlpumpe, {

    headers: {
      'Content-Type': 'application/json'
    },
   
});

if(!responsePumpe.ok){
  throw new Error('Something went wrong!');
}
const responsePumpeData=await responsePumpe.json();
//transform to array
//console.log(responsePumpeData, 'responsePumpeData')
const loadedPumpeItems=[];
for (const key in responsePumpeData){
  loadedPumpeItems.push({
    lfdAuftragWartungsPumpeID: responsePumpeData[key].lfdAuftragWartungsPumpeID,
    fkAuftragWartungsChecklisteID: responsePumpeData[key].fkAuftragWartungsChecklisteID,
    Protokolltyp: responsePumpeData[key].Protokolltyp,
    Nr: responsePumpeData[key].Nr,
    Typ:  responsePumpeData[key].Typ,
    Fabrikat:  responsePumpeData[key].Fabrikat,
   
});

}

authCtx.addPumpelist(JSON.stringify(responsePumpeData));

}

fetchPumpeItems().catch(error=>{
  //setIsLoading(false);
  //setHttpError(error.message);
  console.log(error.message);
  });


  let ausfuehrungid=authCtx.ausfuehrungid;
  let urlfrageitems='https://tbsmobile.de/react-fragenlist.php?id='+ausfuehrungid;
  const fetchFrageItems=async()=>{
    setIsLoading(true);
    const responseFrageItem=await fetch(urlfrageitems, {
  
      headers: {
        'Content-Type': 'application/json'
      },
     
  });
  
  if(!responseFrageItem.ok){
    throw new Error('Something went wrong!');
  }
  const responseFrageItemData=await responseFrageItem.json();
  //transform to array
  
  const loadedFrageItems=[];
  if (responseFrageItemData.length>0) {
   
      for (const key in responseFrageItemData){
          loadedFrageItems.push({
            lfdAuftragWartungsAusfuehrungFrageID: responseFrageItemData[key].lfdAuftragWartungsAusfuehrungFrageID,
            fkAuftragWartungsAusfuehrungID: responseFrageItemData[key].fkAuftragWartungsAusfuehrungID,
            fkAuftragWartungsChecklistenFrageID: responseFrageItemData[key].fkAuftragWartungsChecklistenFrageID,
            Geprueft: responseFrageItemData[key].Geprueft,
            Wert1:  responseFrageItemData[key].Wert1,
            Wert2:  responseFrageItemData[key].Wert2,
            Wert3:  responseFrageItemData[key].Wert3,
            Massnahmen:  responseFrageItemData[key].Massnahmen,
        });
  
      }
      authCtx.addAntworte(JSON.stringify(responseFrageItemData)); 
} else {
  authCtx.addAntworte(null);
}

  
  }
  
  fetchFrageItems().catch(error=>{
    //setIsLoading(false);
    //setHttpError(error.message);
    console.log(error.message);
    });

}
//end addBereichlistHandler


 function getChecklist(){

  const fetchItems=async()=>{
    setIsLoading(true);
    const response=await fetch(url, {
  // 15 is User Identifier
  
    headers: {
      'Content-Type': 'application/json'
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    // redirect: 'follow', // manual, *follow, error
    // referrerPolicy: 'no-referrer',
  });
  
  // console.log(response)
  if(!response.ok){
  throw new Error('Something went wrong!');
  }
  const responseData=await response.json();
  //transform to array
  const loadedItems=[];
  
  for (const key in responseData){
  
  loadedItems.push({
    id: responseData[key].lfdAuftragWartungsChecklisteID,
    fkAuftragID: responseData[key].fkAuftragID,
    description: responseData[key].Bemerkung,
    type: responseData[key].ChecklistenTyp,
    typeid: responseData[key].fkChecklistenTypID,
  });
  }
  setChecklistList(loadedItems);
  setIsLoading(false);
  
  };
  
  
  fetchItems().catch(error=>{
  setIsLoading(false);
  setHttpError(error.message);
  });
  

}



const clickItemHandler=(item)=>{ 

  if (Number(authCtx.checklistid)!==Number(item.id)){
    setMessage('Wartung beginnen? (Ungespeicherte Daten für andere Checklisten werden gelöscht).');
    // console.log('clickItemHandler')
     //item.id -->checklistid
     authCtx.addChecklistid(item.id);
     addBereichlistHandler(item.id)
    getChecklist();
  } else {
    navigate('/bereiche')
  }

 
  /*
=======
  console.log('clickItemHandler');
  setMessage('Wartung beginnen? (Ungespeicherte Daten für andere Checklisten werden gelöscht.');
    
  authCtx.addChecklistid(item.id);
  addBereichlistHandler(item.id)
 
 getChecklist();
 /*
>>>>>>> 5f2c98e (anna)
  if (Number(authCtx.checklistid)===Number(item.id)){
   
  } else {


   
  }

 */


 


//props.onShow()

}

const actionHandler=()=>{
  console.log('abschliessen')
  setMessage(null);
  navigate('/bereiche')
  //'/bereiche'
  
}


const clearMessage=()=>{
  console.log('clearMessage');
  setMessage(null);
  authCtx.delChecklistid()
 
}


if (isLoading){
  return (
    <div>
    <Header items={pageName}/>
    <div className="wrapper">
        <section >
                <NodataMessage>Loading...</NodataMessage>
          </section>
    </div>
         
    </div>
  );
}
  if (httpError) {
    return (
      <div>
      <Header items={pageName}/>
      <div className="wrapper">
       <section >
                <NodataMessage>{httpError}</NodataMessage>
        </section>
        </div>
  </div>
  );
  }
    
 if (checklistList.length===0){
  return (
    <div>
        <Header items={pageName}/>
        <div className="wrapper">
           <section >
                  <NodataMessage>Keine Daten</NodataMessage>
           </section>
           </div>
  </div>
  );
 }



  return (
    <div>
             {message && <Message onClose={clearMessage} onAction={actionHandler}>{message}</Message>}
      <Header items={pageName}/>
      <div className="wrapper">
      <section >
        <div >  
    
      {
                  checklistList.map((item)=> (
                   
                  <ChecklistItem onClick={clickItemHandler.bind(null, item)}
                          key={item.id}
                          id={item.id}
                          type={item.type}
                         description={item.description}
                  />
                 
                  ))
                }
   
      </div>
   
</section>
</div>
    </div>
  )
}

export default Checklist;
