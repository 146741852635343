
import Header from '../component/Header'
//import './Login.css';
import LogoImage from '../images/Logo.jpg';
import { useState, useRef, useContext } from 'react';
import {   Navigate } from 'react-router-dom';
import AuthContext from '../store/auth-context';



const Login=() =>{
  const authCtx = useContext(AuthContext);
  const nameInputRef = useRef();
  const passwordInputRef = useRef();
  const [pageName, setPageName] = useState( 
    {name: 'TBS Wartung', 
    link1: '/', 
    link2: '/',
    hide1: 'yes',
    hide2: 'yes'
  }
    );

  const [isLogin, setIsLogin] = useState(false);
  
  const [isLoading, setIsLoading] = useState(false);

  //submitHandler
  const submitHandler = (event) => {
    event.preventDefault();

   
   
    console.log(authCtx.userid);
    const enteredName = nameInputRef.current.value;
    const enteredPassword = passwordInputRef.current.value;
    setIsLoading(true);
    let url='https://tbsmobile.de/react-user.php';
let userid;


const resp=fetch(url, {
 

  headers: {
    'Content-Type': 'application/json',
  },
})
.then((res) => {
  setIsLoading(false);
  if (res.ok) {
    return res.json();
  } else {
    return res.json().then((data) => {
      let errorMessage = 'Authentication failed!';
      // if (data && data.error && data.error.message) {
      //   errorMessage = data.error.message;
      // }

      throw new Error(errorMessage);
    });
  }
})
.then((data) => {
 
 let testdata=data.filter(el=>el.Username===enteredName && el.Password===enteredPassword);
 let userfound=data.filter(el=>el.Username===enteredName);
 if (testdata.length>0){
 
   return testdata;
 }
 else if(userfound.length>0){
  let errorMessage = 'Wrong password!';
  // if (data && data.error && data.error.message) {
  //   errorMessage = data.error.message;
  // }

  throw new Error(errorMessage);
 }
 else {
  let errorMessage = 'User not found!';
  // if (data && data.error && data.error.message) {
  //   errorMessage = data.error.message;
  // }

  throw new Error(errorMessage);
 }
})
.catch((err) => {
  alert(err.message);
});


resp.then(res=>{
 // console.log(res[0].lfdUserID);
 // console.log(res[0].Monteur);
  
 userid=res[0].lfdUserID;
 authCtx.login(res[0].lfdUserID);
});



  }

  return (
    <div >

    <Header items={pageName}/>
 
  
      
      <div className="wrapper">

      <section>
            <form className='Form--log'  action="" method="get" autoComplete="off" onSubmit={submitHandler}>
             <div>
                <label >Name:</label>
                <input 
                  type="Name" 
                  id="Name" 
                  name="Name"
                  required
                  ref={nameInputRef}/>
             </div>
             
              <div>

                <label > Passwort:</label>
                <input 
                    type="passWort"
                    id="passwort" 
                    name="PassWort"
                    required 
                    ref={passwordInputRef}/>
              </div>
             
                
       
              {!isLoading && (
              
                  <button className="Anmelden"  id="Anmelden" name="Anmelden">
                      Anmelden
                  </button>
              
                )}
                {isLoading && <p>Bitte warten...</p> }
            

            </form>
          
      </section>
      <div className="Logo">
                    <img id="Logo" name="Lgo" src={LogoImage} alt="" srcSet=""/> 
                   
            </div>
  
            {authCtx.isLoggedIn && <Navigate to="/auftraege" />}
    </div>
    </div>
  )
    
}

export default Login
