

import React from 'react';

const Message=React.memo(props=>{

    let text1='';
    let text2='';
    let text=props.children;
    if (text==='Wartung beginnen? (Ungespeicherte Daten für andere Checklisten werden gelöscht).'){
            text1='Wartung beginnen?'
            text2='(Ungespeicherte Daten für andere Checklisten werden gelöscht).'
    }





    return (
        <React.Fragment>
            <div className="backdrop" onClick={props.onClose}  />
            <div className="message-modal">
               {(text1===null || text1==='') && (<p>{props.children}</p>)}
 
               {text1!=='' && (<div>
                                <h3>{text1}</h3> 
                                <p>{text2}</p>
                        </div>
                            )}

                <div className="message-modal__actions">
                    <button type="button" className="button" onClick={props.onAction}>
                        Ja
                    </button>
                    <button type="button" className="button" onClick={props.onClose}>
                        Nein
                    </button>
                </div>

            </div>

        </React.Fragment>
      
        );

});

export default Message;
