
import React from 'react';

const NodataMessage=React.memo(props=>{






    return (
        <React.Fragment>
            <div className="list1">
                    <p>{props.children}</p>
            </div>
        </React.Fragment>
      
        );

});

export default NodataMessage;