
import { Link } from 'react-router-dom'
const WartungItem=(props)=>{
   


    //const price=`$${props.price.toFixed(2)}`;

  

    return (
        
 
        <Link to='/bereiche'> 
        <div className='linkliste' >
            <div className='linktext'>
                {props.name} {props.description} {props.bz} {props.date} {props.bemerkung} {props.user}
                
        
            </div>
            <div className='linkbutton'>
            
                pfeil
            </div>
         </div>
        </Link>
    
   );
};

export default WartungItem;