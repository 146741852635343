

const PumpeItemDetail=(props)=>{
    
    let protokolltyp=props.protokolltyp;

    let pumpename=props.nr+' '+props.name+' '+props.description;
    let probierleitung=props.probierleitung
    let motor;
    if (Number(props.motor)===2){
        motor='Diesel'
    }
    if (Number(props.motor)===1){
        motor='Elektro'
    }
    
    let betriebspunkt=props.betriebspunkt1+' l/min '+props.betriebspunkt2+' bar';
    let netz1=props.schaltschranknetz1;
    let netz2=props.schaltschranknetz2;
 //console.log(props)
    //const path=props.path;
    return (<div className="pumpen">
                <h1>{pumpename}</h1>
                <div > 
                    <p >Probierleitung DN: {probierleitung}</p>
                    
                    {protokolltyp==='1' && (<p >Motor: {motor}</p>)} 
                     {protokolltyp!=='1' && (<p >Schaltschrank Netz vorhanden?: {netz1}</p>)} 
                     {protokolltyp!=='1' && (<p >Schaltschrank Notnetz vorhanden?: {netz2}</p>)} 
                    
                    <p >Betriebspunkt: {betriebspunkt}</p>
                  
                </div>
       
        
    </div>);
};

export default PumpeItemDetail;