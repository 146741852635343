import React, { useState, useEffect, useCallback } from 'react';

const AuthContext = React.createContext({
    userid: 0,
    isLoggedIn: false,
    auftragid: 0,
    checklistid: 0,
    bereichid: 0,
    bereichlist: [],
    fragenlist: [],
    pumplelist: [],
    pumpeid: 0,
    ausfuehrungid: 0,
    ausfuehrungstatusid: 0,
    antworte: [],
    pumpedata: [],
    login: (userid) => {},
    logout: () => {},
    addAuftragid: (auftragid)=>{},
    delAuftragid:()=>{},
    addChecklistid: (checklistid)=>{},
    delChecklistid: ()=>{},
    addBereichid: (bereichid)=>{},
    delBereichid: ()=>{},
    addBereichelist: (bereichlist)=>{},
    delBereichelist: ()=>{},
    addFragenlist: (fragenlist)=>{},
    delFragenlist: ()=>{},
    addPumpelist: (pumplelist)=>{},
    delPumpelist: ()=>{},
    addPumpeid: (pumpeid)=>{},
    delPumpeid:()=>{},
    addAusfuehrungid: (ausfuehrungid)=>{},
    delAusfuehrungid:()=>{},
    addAusfuehrungstatusid: (ausfuehrungstatusid)=>{},
    delAusfuehrungstatusid:()=>{},
    addAntworte: (antworte)=>{},
    delAntworte:()=>{},
   

  });

  const retrieveStoredUser = () => {
    const storedUser = localStorage.getItem('userid');
    return {
      userid: storedUser
    };
  };

  const retrieveStoredAuftrag = () => {
    const storedAuftrag = localStorage.getItem('auftragid');
    return {
      auftragid: storedAuftrag
    };
  };

  const retrieveStoredAusfuehrung = () => {
    const storedAusfuehrung= localStorage.getItem('ausfuehrungid');
    return {
      ausfuehrungid: storedAusfuehrung
    };
  };

  const retrieveStoredAusfuehrungStatus = () => {
    const storedAusfuehrungStatus= localStorage.getItem('ausfuehrungstatusid');
    return {
      ausfuehrungstatusid: storedAusfuehrungStatus
    };
  };

  const retrieveStoredChecklist = () => {
    const storedChecklist = localStorage.getItem('checklistid');
    return {
        checklistid: storedChecklist
    };
  };

  const retrieveStoredBereich = () => {
    const storedBereich = localStorage.getItem('bereichid');
    return {
      bereichid: storedBereich
    };
  };

  const retrieveStoredBereichlist = () => {
    const storedBereichlist = localStorage.getItem('bereichlist');
    return {
      bereichelist: storedBereichlist
    };
  };

  const retrieveStoredFrangenlist = () => {
    const storedFragenlist = localStorage.getItem('fragenlist');
    return {
      fragenlist: storedFragenlist
    };
  };

  const retrieveStoredPumpelist = () => {
    const storedPumpelist = localStorage.getItem('pumpelist');
    return {
      pumpelist: storedPumpelist
    };
  };

  const retrieveStoredPumpe = () => {
    const storedPumpe = localStorage.getItem('pumpeid');
    return {
      pumpeid: storedPumpe
    };
  };

  const retrieveStoredAntworte = () => {
    const storedAntworte = localStorage.getItem('antworte');
    return {
      antworte: storedAntworte
    };
  };

  const retrieveStoredAntwortePumpe = () => {
    const storedAntwortePumpe = localStorage.getItem('pumpedata');
    return {
      pumpedata: storedAntwortePumpe
    };
  };

  export const AuthContextProvider = (props) => {

  

   const userData = retrieveStoredUser();
    
    let initialuser;
    if (userData) {
        initialuser = userData.userid;
    }
    const [userid, setUserid] = useState(initialuser);



    const auftragData = retrieveStoredAuftrag();
     
    let initialauftrag;
    if (auftragData) {
       initialauftrag = auftragData.auftragid;
    }
    const [auftragid, setAuftragid] = useState(initialauftrag);


const checklistData = retrieveStoredChecklist();
     
     let initialchecklist;
     if (checklistData) {
        initialchecklist = checklistData.checklistid;
     }
      

    const [checklistid, setChecklistid] = useState(initialchecklist);

    

    //Ausfuehrung
    const ausfuehrungData = retrieveStoredAusfuehrung();
     
     let initialausfuehrung;
     if (ausfuehrungData) {
      initialausfuehrung = ausfuehrungData.ausfuehrungid;
     }
      

    const [ausfuehrungid, setAusfuehrungid] = useState(initialausfuehrung);
    
      //Ausfuehrung
      const ausfuehrungStatusData = retrieveStoredAusfuehrungStatus();
     
      let initialausfuehrungstatus;
      if (ausfuehrungStatusData) {
        initialausfuehrungstatus = ausfuehrungStatusData.ausfuehrungstatusid;
      }
       
 
     const [ausfuehrungstatusid, setAusfuehrungStatusid] = useState(initialausfuehrungstatus);



    const bereichData = retrieveStoredBereich();
     
    let initialbereich;
     if (bereichData) {
      initialbereich = bereichData.bereichid;
     }

     const [bereichid, setBereichid] = useState(initialbereich);

  
    const bereichlistData = retrieveStoredBereichlist();
      
    let initialbereichlist;
     if (bereichlistData) {
      initialbereichlist = bereichlistData.bereichelist;
     }

     const [bereichlist, setBereichlist] = useState(initialbereichlist);

     //fragenlist

    const fragenlistData = retrieveStoredFrangenlist();
      
    let initialfragenlist;
     if (fragenlistData) {
      initialfragenlist = fragenlistData.fragenlist;
     }

     const [fragenlist, setFragenlist] = useState(initialfragenlist);

     //pumpelist
     
    

    const pumpelistData = retrieveStoredPumpelist();
      
    let initialpumpelist;
     if (pumpelistData) {
      initialpumpelist = pumpelistData.pumpelist;
     }

     const [pumpelist, setPumpelist] = useState(initialpumpelist);

     //Antworte

     
    const antwortelistData = retrieveStoredAntworte();
      
    let initialantwortelist;
     if (antwortelistData) {
      initialantwortelist = antwortelistData.antworte;
     }

     const [antworte, setAntwortelist] = useState(initialantwortelist);

     
    const pumpeAntwortData = retrieveStoredAntwortePumpe();
      
    let initialantwortepumpedata;
     if (pumpeAntwortData) {
      initialantwortepumpedata = pumpeAntwortData.pumpedata;
     }

     const [pumpedata, setPumpedata] = useState(initialantwortepumpedata);

   
     //

     const pumpeData = retrieveStoredPumpe();
     
     let initialPumpe;
      if (pumpeData) {
       initialPumpe = pumpeData.pumpeid;
      }
 
      const [pumpeid, setPumpeid] = useState(initialPumpe);




    const userIsLoggedIn = !!userid;
  
    const logoutHandler = useCallback(() => {
       
        setUserid(null);
        localStorage.removeItem('userid');
      /*
      localStorage.removeItem('token');
      localStorage.removeItem('expirationTime');
  
      if (logoutTimer) {
        clearTimeout(logoutTimer);
      }
        */
    }, []);

    const loginHandler = (userid) => {
        setUserid(userid);
        localStorage.setItem('userid', userid);
      
    };
  
    const addAuftragidHandler=(auftragid)=>{

      setAuftragid(auftragid);
      localStorage.setItem('auftragid', auftragid);
    };

    const delAuftragidHandler=()=>{
      
      setAuftragid(null);
      delChecklistidHandler();
      delBereichidHandler();
      delPumpeIDHandler();
      delAntworteHandler();
      delBereichlistHandler();
      delPumpelistHandler();
      delFragenlistHandler();
      localStorage.removeItem('auftragid');
    }
  
    const addChecklistidHandler=(checklistid)=>{

      setChecklistid(checklistid);
      localStorage.setItem('checklistid', checklistid);
    };

    const delChecklistidHandler=()=>{
      setChecklistid(null);
      localStorage.removeItem('checklistid');
    }

    const addBereichidHandler=(bereichid)=>{

      setBereichid(bereichid);
      localStorage.setItem('bereichid', bereichid);
    };

    const delBereichidHandler=()=>{
      setBereichid(null);
      localStorage.removeItem('bereichid');
    }

    const addBereichlistHandler=(bereichlist)=>{
      setBereichlist(bereichlist);
      localStorage.setItem('bereichlist', bereichlist);
    }


    const delBereichlistHandler=()=>{
      setBereichlist(null)
      localStorage.removeItem('bereichlist');
    }


    const addFragenlistHandler=(fragenlist)=>{
      setFragenlist(fragenlist);
      localStorage.setItem('fragenlist', fragenlist);
    }


    const delFragenlistHandler=()=>{
      setFragenlist(null)
      localStorage.removeItem('fragenlist');
    }


    const addPumpelistHandler=(pumpelist)=>{
      setPumpelist(pumpelist);
      localStorage.setItem('pumpelist', pumpelist);
    }


    const delPumpelistHandler=()=>{
      setPumpelist(null)
      localStorage.removeItem('pumpelist');
    }

    const addPumpeIDHandler=(pumpeid)=>{
      setPumpeid(pumpeid);
      localStorage.setItem('pumpeid', pumpeid);
    }

    const delPumpeIDHandler=()=>{
      setPumpeid(null);
      localStorage.removeItem('pumpeid');
    }

    const addAusfuehrungidHandler=(ausfuehrungid)=>{
      setAusfuehrungid(ausfuehrungid);
      localStorage.setItem('ausfuehrungid', ausfuehrungid);
    }

    const delAusfuehrungidHandler=()=>{
      setAusfuehrungid(null);
      localStorage.removeItem('ausfuehrungid');
    }

    const addAusfuehrungStatusidHandler=(ausfuehrungstatusid)=>{
      setAusfuehrungStatusid(ausfuehrungstatusid);
      localStorage.setItem('ausfuehrungstatusid', ausfuehrungstatusid);
    }

    const delAusfuehrungStatusidHandler=()=>{
      setAusfuehrungStatusid(null);
      localStorage.removeItem('ausfuehrungstatusid');
    }

    const addAntworteHandler=(antworte)=>{
      setAntwortelist(antworte);
      localStorage.setItem('antworte', antworte);
    }

    const delAntworteHandler=()=>{
      setAntwortelist(null);
      localStorage.removeItem('antworte');
    }

    const addPumpedataHandler=(pumpedata)=>{
      setPumpedata(pumpedata);
      localStorage.setItem('pumpedata', pumpedata);
    }

    const delPumpedataHandler=()=>{
      setPumpedata(null);
      localStorage.removeItem('pumpedata');
    }

    
   

    const contextValue = {
      userid: userid,
      isLoggedIn: userIsLoggedIn,
      auftragid: auftragid,
      ausfuehrungid: ausfuehrungid,
      ausfuehrungstatusid: ausfuehrungstatusid,
      checklistid: checklistid,
      bereichid: bereichid,
      bereichlist: bereichlist,
      fragenlist: fragenlist,
      pumpelist: pumpelist,
      pumpeid: pumpeid,
      login: loginHandler,
      logout: logoutHandler,
      antworte: antworte,
      pumpedata: pumpedata,
      addAuftragid: addAuftragidHandler,
      delAuftragid: delAuftragidHandler,
      addChecklistid: addChecklistidHandler,
      delChecklistid: delChecklistidHandler,
      addBereichid: addBereichidHandler,
      delBereichid: delBereichidHandler,
      addBereichlist: addBereichlistHandler,
      delBereichlist: delBereichlistHandler,
      addFragenlist: addFragenlistHandler,
      delFragenlist: delFragenlistHandler,
      addPumpelist: addPumpelistHandler,
      delPumpelist: delPumpelistHandler,
      addPumpeid: addPumpeIDHandler,
      delPumpeid:delPumpeIDHandler,
      addAusfuehrungid: addAusfuehrungidHandler,
      delAusfuehrungid: delAusfuehrungidHandler,
      addAusfuehrungStatusid: addAusfuehrungStatusidHandler,
      delAusfuehrunStatusgid: delAusfuehrungStatusidHandler,
      addAntworte:  addAntworteHandler,
      delAntworte: delAntworteHandler,
      addPumpedata: addPumpedataHandler,
      delPumpedata: delPumpedataHandler,
     
    };
  

    return (
      <AuthContext.Provider value={contextValue}>
        {props.children}
      </AuthContext.Provider>
    );
  };



  export default AuthContext;
