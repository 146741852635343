//import { Link } from 'react-router-dom'
import Header from '../component/Header';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useEffect, useContext } from 'react';
//import ChecklistContext from '../store/checklist-context';
import AuthContext from '../store/auth-context';
import BereichDetail from '../component/BereichDetail';

import FrageForm2 from '../component/FrageForm2'
import { Fragment } from 'react';
import Massnahmen from '../component/Message/Massnahmen';

function FragenNew() {
  const [message, setMessage]=useState();
  const navigate = useNavigate();
  const authCtx = useContext(AuthContext);
    const pageName=
      {name: 'Fragen', 
      link1: '/fragen', 
      link2: '/'};
     
     

    const fragenList=[];
    const checklistList=[];
    let antwData1=[];
    if (authCtx.antworte!==null){
      antwData1=JSON.parse(authCtx.antworte);
    } 
    const [array, setArray]=useState([]);
    const [antworteList, setAntworteList]=useState(antwData1);
    const bereichid=authCtx.bereichid;
    const bereichlist=authCtx.bereichlist;
   
  
    let responseData1=JSON.parse(bereichlist);
        responseData1=responseData1.filter(el=> el.lfdAuftragWartungsBereichID===bereichid);
    let responseDatafrage1=JSON.parse(authCtx.fragenlist);
    let responseDatafrage2=responseDatafrage1.filter(el=>el.fkAuftragWartungsChecklisteID===authCtx.checklistid && el.fkAuftragWartungsBereichID===bereichid);
    for (const key in responseDatafrage2){
        checklistList.push({
            id: responseDatafrage2[key].lfdAuftragWartungsChecklistenFrageID,
            name: responseDatafrage2[key].ChecklistenFrage,
            pos: responseDatafrage2[key].PosNr,
            wert1: responseDatafrage2[key].Wert1,
            wert2: responseDatafrage2[key].Wert2,
            wert3: responseDatafrage2[key].Wert3,
            wertbeschriftung1: responseDatafrage2[key].WertBeschriftung1,
            wertbeschriftung2: responseDatafrage2[key].WertBeschriftung2,
            wertbeschriftung3: responseDatafrage2[key].WertBeschriftung3,
          });
      }
  
      const hasItems=checklistList.length>0;
   // const antworteSaved=authCtx.antworte;
   // const [antworteSaved, setAntworteSaved]=useState([]);
   
   if (bereichlist!==null) {
      for (const key in responseData1){
    
          fragenList.push({
              id: responseData1[key].lfdAuftragWartungsBereichID,
              name: responseData1[key].ChecklistenBereich,
              description: responseData1[key].Wert1,
              pos: responseData1[key].PosNr,
              wert2:  responseData1[key].Wert2,
              wert3:  responseData1[key].Wert3,
              wert4:  responseData1[key].Wert4,
              wert5:  responseData1[key].Wert5,
              wert6:  responseData1[key].Wert6,
              wert1exist: responseData1[key].Wert1Exist,
              wert2exist: responseData1[key].Wert2Exist,
              wert3exist: responseData1[key].Wert3Exist,
              wert4exist: responseData1[key].Wert4Exist,
              wert5exist: responseData1[key].Wert5Exist,
              wert6exist: responseData1[key].Wert6Exist,
              WertBeschriftung1: responseData1[key].WertBeschriftung1,
              WertBeschriftung2: responseData1[key].WertBeschriftung2,
              WertBeschriftung3: responseData1[key].WertBeschriftung3,
              WertBeschriftung4: responseData1[key].WertBeschriftung4,
              WertBeschriftung5: responseData1[key].WertBeschriftung5,
              WertBeschriftung6: responseData1[key].WertBeschriftung6,
              countFr: responseData1[key].countFr,
              CountGeprueft: responseData1[key].CountGeprueft,
      });
  }
  }


  useEffect(()=>{
    if (array.length===0) {
                       updateArr()
    }
  },
  [])
 
  

 function updateArr(){
  let newarray=[];
  if (checklistList.length>0) {

  
  for (let i = 0; i < checklistList.length; i++) {
    let newRow={};
    newRow.key=checklistList[i].id;
    newRow.id=checklistList[i].id;
    newRow.pos=checklistList[i].pos; //props['q'+i];
    newRow.name=checklistList[i].name;
    newRow.wert1=checklistList[i].wert1;
    newRow.wert2=checklistList[i].wert2;
    newRow.wert3=checklistList[i].wert3;
    newRow.wertbeschriftung1=checklistList[i].wertbeschriftung1;
    newRow.wertbeschriftung2=checklistList[i].wertbeschriftung2;
    newRow.wertbeschriftung3=checklistList[i].wertbeschriftung3;

   

  
    if (antworteList!==null) {
      if ( antworteList.length>0) {
        let filteredAntworte=antworteList.filter(el=>el.fkAuftragWartungsChecklistenFrageID===checklistList[i].id)
        if (filteredAntworte.length>0){
    //      console.log(filteredAntworte, 'filteredAntworte')
          newRow.geprueft=filteredAntworte[0].Geprueft;
          newRow.massnahmen=filteredAntworte[0].Massnahmen;
          newRow.antw1=filteredAntworte[0].Wert1;
          newRow.antw2=filteredAntworte[0].Wert2;
          newRow.antw3=filteredAntworte[0].Wert3;
        } else {
          newRow.geprueft=null;
          newRow.massnahmen='';
          newRow.antw1='';
          newRow.antw2='';
          newRow.antw3='';
        }
      } else {
        newRow.geprueft=null;
      newRow.massnahmen='';
      newRow.antw1='';
      newRow.antw2='';
      newRow.antw3='';
      }
     
    
    
    } else {
      newRow.geprueft=null;
      newRow.massnahmen='';
      newRow.antw1='';
      newRow.antw2='';
      newRow.antw3='';
    }
  
  
    newarray.push(newRow);
   
   
    //array=newarray;

   
  }
  
  setArray(newarray);
 // console.log(newarray, 'b')
  }
 }
  
 
 function editBereichlist(antworteList){
    let countfr=checklistList.length;
    let countgepr=0;
    for (const k in checklistList){
  //authCtx.checklistid && el.fkAuftragWartungsBereichID===bereichid
      let filteredAntworte=antworteList.filter(el=>el.fkAuftragWartungsChecklistenFrageID===checklistList[k].id)
  
      if (filteredAntworte){
        if (filteredAntworte.length>0){
            countgepr=countgepr+1;
          }
      }
  }
    let Num1=countfr.toString();
    let Num2=countgepr.toString();
  
    let bereichlistData=JSON.parse(bereichlist);
    let updatedRow=bereichlistData.filter(el=>el.lfdAuftragWartungsBereichID===authCtx.bereichid);
 //let updatedRowIndex=bereichlistData.findIndex(el=>el.lfdAuftragWartungsBereichID===authCtx.bereichid);
// console.log(updatedRow)
// console.log(bereichlistData[updatedRowIndex])
if (updatedRow){
  updatedRow[0].countFr=Num1;
  updatedRow[0].CountGeprueft=Num2;
}
 

authCtx.addBereichlist(JSON.stringify(bereichlistData));


 
  //fragenList.id -- bereich id CountGeprueft countFr

 }
 
 const chechItemHandler2=(row)=>{
  
  for (let z=0; z < array.length; z++){
    //  console.log(array[z].id,array[z].geprueft)
      if (array[z].id===row.fkAuftragWartungsChecklistenFrageID){
        let updatedRow=array[z];
        updatedRow.geprueft=row.Geprueft;
   
        //updateArr();
        array[z]=updatedRow;
      }
         }

 }

    const chechItemHandler=rowCheck=>{
      
    let antw=[];
    let responseData1=JSON.parse(authCtx.antworte);
        setAntworteList(responseData1);
 
    
 
         
      //updateArr();
      //check if the answer already exists
    }

   
    
 function editBereichlist(){

  let bereichlist=authCtx.bereichlist;
 let bereichlistData=JSON.parse(bereichlist);
 //console.log(bereichlistData)
 let fragenlist=authCtx.fragenlist;
 let fragenlistData=JSON.parse(fragenlist);

for (const z in bereichlistData){
  let id=bereichlistData[z].lfdAuftragWartungsBereichID;

  let filteredfragenlistData=fragenlistData.filter(el=>el.fkAuftragWartungsBereichID===id);
//  console.log(id, filteredfragenlistData);
 
  if(filteredfragenlistData.length>0){
    let countfr=0;
    let countgepr=0;
    for (const k in filteredfragenlistData){
      
      let frNumstr=filteredfragenlistData[k].lfdAuftragWartungsChecklistenFrageID;
      let frNumstr1=frNumstr.toString();
      let filteredAntworte=antworteList.filter(el=>el.fkAuftragWartungsChecklistenFrageID===frNumstr1)
      //console.log(filteredAntworte);
      if (filteredAntworte){
        if (filteredAntworte.length>0){
          countgepr++;
         // console.log(authCtx.bereichid, filteredAntworte)
        }
      }
    countfr++;
    }

    let Num1=countfr.toString();
    let Num2=countgepr.toString();
  //  console.log(Num1, Num2)
    if(Num1===Num2) {
      let updatedRow=bereichlistData.filter(el=>el.lfdAuftragWartungsBereichID===authCtx.bereichid);
      //let updatedRowIndex=bereichlistData.findIndex(el=>el.lfdAuftragWartungsBereichID===authCtx.bereichid);
    
     // console.log(bereichlistData[updatedRowIndex])
     if (updatedRow){
       updatedRow[0].countFr=Num1;
       updatedRow[0].CountGeprueft=Num2;
      // console.log(JSON.stringify(bereichlistData))
       authCtx.addBereichlist(JSON.stringify(bereichlistData));
      
     }
    }
   
  }
 
  
}

}

   
function antworte(row){
  // console.log(row)
   //  console.log('antworte');
   let arr=[];
   
   if (authCtx.antworte===null){
   arr.push(row);
   authCtx.addAntworte(JSON.stringify(arr))
   } else {
   let responseData1=JSON.parse(authCtx.antworte);
   arr=responseData1;
   //console.log(arr, arr[0].fkAuftragWartungsChecklistenFrageID, row.fkAuftragWartungsChecklistenFrageID, arr[0].lfdAuftragWartungsAusfuehrungFrageID);
   let filtered=arr.filter(el=>el.fkAuftragWartungsChecklistenFrageID===row.fkAuftragWartungsChecklistenFrageID)
   // console.log(filtered);
   if (filtered.length>0){
     for (let z=0; z < arr.length; z++){
      
       if (arr[z].fkAuftragWartungsChecklistenFrageID===row.fkAuftragWartungsChecklistenFrageID){
         let updatedRow=arr[z];
         updatedRow=row;
         
         arr[z]=updatedRow;
      //   console.log(updatedRow,row);
      //   console.log(filtered)
       }
          }
   
   } else {
    
     arr.push(row);
    
   }
   authCtx.addAntworte(JSON.stringify(arr))
   }
   
   }


const actionHandler=(message)=>{
  console.log(message)
  console.log('abschliessen');
  if (message.geprueft==='0' && message.massnahmen.length<1){
    alert('Massnahmen prüfen');
    return;
  }
  let filtered=array.filter(el=>Number(el.id)===Number(message.id))
  filtered[0].massnahmen=message.massnahmen;
  filtered[0].geprueft=message.geprueft;

  let newRow={};
  let lfdAuftragWartungsAusfuehrungFrageID=0;
  let responseData1=JSON.parse(authCtx.antworte);

  let filteredresponseData=responseData1.filter(el=>Number(el.fkAuftragWartungsChecklistenFrageID)===Number(message.id));
  if (filteredresponseData.length>0){
    lfdAuftragWartungsAusfuehrungFrageID=filteredresponseData[0].lfdAuftragWartungsAusfuehrungFrageID
  }

  newRow.lfdAuftragWartungsAusfuehrungFrageID=lfdAuftragWartungsAusfuehrungFrageID;
  newRow.fkAuftragWartungsAusfuehrungID=authCtx.ausfuehrung;
  newRow.fkAuftragWartungsChecklistenFrageID=message.id;
  newRow.Wert1= filtered[0].antw1;
  newRow.Wert2= filtered[0].antw2;
  newRow.Wert3= filtered[0].antw3;
  newRow.Geprueft=message.geprueft;
  newRow.Massnahmen=message.massnahmen;
  newRow.updated=1;
  antworte(newRow)


  setMessage(null);
  
  //'/bereiche'
  
}


const clearMessage=()=>{
  console.log('clearMessage');
  setMessage(null);
 
 
}

const openMassnahmenHandler=(frage)=>{

  setMessage({name: 'Massnahmen eingeben', 
  massnahmen: frage.massnahmen,
  id: frage.id,
  geprueft: '0'
  });
}

const openEditMassnahmenHandler=(frage)=>{

  setMessage({name: 'Massnahmen eingeben', 
  massnahmen: frage.massnahmen,
  id: frage.id,
  geprueft: frage.geprueft
  });
}


const changeMassnahmentext=newtext=>{
  console.log(newtext)
  setMessage(
    {name:'Massnahmen eingeben', 
    massnahmen: newtext.text,
    id: newtext.id,
    geprueft: newtext.geprueft,
  });
}
      /*
      useEffect(()=>{
  console.log(antworteList, 'antworteList')

  if (antworteList.length>0){
    //authCtx.addAntworte(JSON.stringify(antworteList))
   }
 
  // updateArr();
console.log('chechItemHandler')
 },[chechItemHandler])
    let newarray=[];
      for (let i = 0; i < checklistList.length; i++) {
        let newRow={};
        newRow.key=checklistList[i].id;
        newRow.id=checklistList[i].id;
        newRow.pos=checklistList[i].pos; //props['q'+i];
        newRow.name=checklistList[i].name;
        newRow.wert1=checklistList[i].wert1;
        newRow.wert2=checklistList[i].wert2;
        newRow.wert3=checklistList[i].wert3;
        newRow.wertbeschriftung1=checklistList[i].wertbeschriftung1;
        newRow.wertbeschriftung2=checklistList[i].wertbeschriftung2;
        newRow.wertbeschriftung3=checklistList[i].wertbeschriftung3;
    
        if (antworteSavedData.length>0) {
          let filteredAntworte=antworteSavedData.filter(el=>el.fkAuftragWartungsChecklistenFrageID===checklistList[i].id)
          if (filteredAntworte){
            newRow.geprueft=filteredAntworte[0].Geprueft;
          } else {
            newRow.geprueft=null;
          }
        } else {
          newRow.geprueft=null;
        }
      
      
        newarray.push(newRow);
       
      }
      array=newarray;
  //setArray(newarray);
    
  */
 
   
  const clickWeiterHandler=()=>{
    navigate('/fragen')
   }


    const frageTable= (
        <Fragment>
        {array.length===0 && (
            <div>Keine Fragen</div>
        )
        } 
        {array.length>0 &&
            (<div> 
            
                
                            <p>Geprüft</p>
                      
                            <p>Typ (Ja/Nein)</p>
                       
                            <p>Erforderliche Maßnahmen</p>
                      
                    
                      { (
                          array.map((frage)=> (
<FrageForm2 
  key={frage.id}
  id={frage.id}
  pos={frage.pos}
  name={frage.name}
  wert1={frage.wert1}
  wert2={frage.wert2}
  wert3={frage.wert3}
  antw1={frage.antw1}
  antw2={frage.antw2}
  antw3={frage.antw3}
  geprueft={frage.geprueft}
  massnahmen={frage.massnahmen}
  wertbeschriftung1={frage.wertbeschriftung1}
  wertbeschriftung2={frage.wertbeschriftung2}
  wertbeschriftung3={frage.wertbeschriftung3}
  onCheck={chechItemHandler2}
  ausfuehrung={authCtx.ausfuehrungid}
  onShowTextInput={openMassnahmenHandler.bind(null,frage)}
  onEditTextInput={openEditMassnahmenHandler.bind(null,frage)}
/>))

)}

   
</div>)
        }
            
    </Fragment>);



    if (fragenList.length===0) {
      return (
          <div>
              <Header items={pageName}/>
                  <section >
                          <p>Keine Datei</p>
                  </section>
          </div>
    );
    }
  
    const frageChecklistContent= (
      <Fragment>
            { hasItems && (
          <div> 
                {frageTable}
          </div>
   
  
      )}
    { !hasItems && ( 
      
          <p>
            Keine Fragen
          </p>)
    }
  </Fragment>);
  
  
          
      return (
        <div>
             {message && <Massnahmen onClose={clearMessage} onAction={actionHandler.bind(null,message)} items={message} onChangetext={changeMassnahmentext}>{message.name}</Massnahmen>}
            <Header items={pageName}/>
            <div className="wrapper">
            <section >
            {
                fragenList.map((frage)=> (
                        <BereichDetail 
                              key={frage.id}
                              id={frage.id}
                              name={frage.name}
                              description={frage.description}
                              pos={frage.pos}
                              wert2={frage.wert2}
                              wert3={frage.wert3}
                              wert4={frage.wert4}
                              wert5={frage.wert5}
                              wert6={frage.wert6}
                              wert1exist={frage.wert1exist}
                              wert2exist={frage.wert2exist}
                              wert3exist={frage.wert3exist}
                              wert4exist={frage.wert4exist}
                              wert5exist={frage.wert5exist}
                              wert6exist={frage.wert6exist}
                              WertBeschriftung1={frage.WertBeschriftung1}
                              WertBeschriftung2={frage.WertBeschriftung2}
                              WertBeschriftung3={frage.WertBeschriftung3}
                              WertBeschriftung4={frage.WertBeschriftung4}
                              WertBeschriftung5={frage.WertBeschriftung5}
                              WertBeschriftung6={frage.WertBeschriftung6}
                          />))
              }
          
            </section>
          
           
        <section>
<div>
    {frageChecklistContent}
</div>
            </section>
            <div className="footer-wrapper"> 
                <div className="bnt-right"> 
                  <button  onClick={clickWeiterHandler} >Weiter</button>
              </div>
        </div>
        </div>
        </div>
      )
    }
    
    export default FragenNew
    

