
import Modal from "./UI/Modal";
import { useContext, useState } from 'react';
import React from 'react';
//import '../component/Abschliessen.css'
import AuthContext from '../store/auth-context';


const Abschliessen=props=>{
    const [isLoading, setIsLoading]=useState(false);
    const authCtx = useContext(AuthContext);

   
    
    
    


 

      
      
    async function updateantworte (){

        setIsLoading(true);
        let ausfuehrungid=authCtx.ausfuehrungid;
        let urlfrageitems='https://tbsmobile.de/react-fragenlist.php?id='+ausfuehrungid;
        const responseFrageItem=await fetch(urlfrageitems, {
      
          headers: {
            'Content-Type': 'application/json'
          },
         
      });
      
      if(!responseFrageItem.ok){
        throw new Error('Something went wrong!');
      }
    
      console.log(responseFrageItem); 
    
      const responseFrageItemData=await responseFrageItem.json();
      //transform to array
      //console.log(responseFrageItemData);
      //const loadedFrageItems=[];
      if (responseFrageItemData.length>0) {
       /*
          for (const key in responseFrageItemData){
              loadedFrageItems.push({
                lfdAuftragWartungsAusfuehrungFrageID: responseFrageItemData[key].lfdAuftragWartungsAusfuehrungFrageID,
                fkAuftragWartungsAusfuehrungID: responseFrageItemData[key].fkAuftragWartungsAusfuehrungID,
                fkAuftragWartungsChecklistenFrageID: responseFrageItemData[key].fkAuftragWartungsChecklistenFrageID,
                Geprueft: responseFrageItemData[key].Geprueft,
                Wert1:  responseFrageItemData[key].Wert1,
                Wert2:  responseFrageItemData[key].Wert2,
                Wert3:  responseFrageItemData[key].Wert3,
                Massnahmen:  responseFrageItemData[key].Massnahmen,
            });
      
          }
          */
          authCtx.addAntworte(JSON.stringify(responseFrageItemData)); 
       //   setIsLoading(false);
    } 
    
    }

    
    const saveModalHandler=()=>{
        console.log('save1');
       

      updateantworte();

        props.onSave()
      };

    const modalActions= (
        <div className="actions">
                <button className="button" onClick={saveModalHandler}>
                    Ja
                </button>
                <button className="button" onClick={props.onClose}>
                    Nein
                </button>
        </div>
        );

        const wartungModalContent= (
            <React.Fragment>
           
            <div className="total">
            <span>Wartung abschließen?</span>
            </div>
           
            {modalActions}
        
        
        </React.Fragment>);

return (
    <Modal onClose={props.onClose}>
      {wartungModalContent}
    </Modal>
    );
};

export default Abschliessen;