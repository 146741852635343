import { Link, Navigate } from 'react-router-dom'
import { useEffect, useContext } from 'react';
import Header from '../component/Header';
import React, { useState } from 'react';
import AuthContext from '../store/auth-context';
import PumpeItem from '../component/PumpeItem';
import { useNavigate } from "react-router-dom";
import NodataMessage from '../component/NodataMessage';

function Pumpen() {
  const [pumpeList, setPumpeList]=useState([]);
  const [pageName, setPageName] = useState( 
    {name: 'Pumpen', 
    link1: '/bereiche', 
    link2: '/'});

    const authCtx = useContext(AuthContext);
    const navigate = useNavigate();
    const responseData=JSON.parse(authCtx.pumpelist);
   
    const loadedItems=[];
   
    
      for (const key in responseData){
      
            loadedItems.push({
              id: responseData[key].lfdAuftragWartungsPumpeID,
              nr: responseData[key].Nr,
              type: responseData[key].Typ,
              fabrikat: responseData[key].Fabrikat,
              protokolltyp: responseData[key].Protokolltyp,
              saved: responseData[key].saved
            
        });
      }
      

  useEffect(()=>{
    setPumpeList(loadedItems)
    console.log(pumpeList);
  },[])
    
 
   const clickWeiterHandler=()=>{
    navigate('/bereiche')
   }

    const clickItemHandler=(pumpe)=>{ 
      // console.log(frage);
       //item.id -->checklistid
       authCtx.addPumpeid(pumpe.id);
       navigate('/pumpedetail')
     }

     if (pumpeList.length===0) {
      return (
        <div>
        <Header items={pageName}/>
      <section >
        <NodataMessage>Keine Datei</NodataMessage>
    </section>
    </div>
    );
    }


  return (
    <div> 
      <Header items={pageName}/>
      <div className="wrapper">
      <section>
    
       
          {
            pumpeList.map((pumpe)=> (
                    <PumpeItem  onClick={clickItemHandler.bind(null, pumpe)}
                          key={pumpe.id}
                          id={pumpe.id}
                          name={pumpe.type}
                          description={pumpe.fabrikat}
                         nr={pumpe.nr}
                         protokolltyp={pumpe.protokolltyp}
                         saved={pumpe.saved}
                      />))
          }
      
      
        
        </section>
        <div className="footer-wrapper"> 
                <div className="bnt-right"> 
                  <button  onClick={clickWeiterHandler} >Weiter</button>
              </div>
        </div>
        
    </div>
    </div>
  )
}

export default Pumpen
