import React from 'react'
import { createRoot } from 'react-dom/client'
import './css/index.css'
import App from './App'
import ReactDOM from 'react-dom/client';


const container = document.getElementById('app')


const root = ReactDOM.createRoot(container)
root.render(
    <App />
)
