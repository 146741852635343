
import Header from '../component/Header'
import React, { useState } from 'react';

import { useNavigate } from "react-router-dom";
import Message from '../component/Message/Message';
import AuthContext from '../store/auth-context';
import { useEffect, useContext } from 'react';
import { useRef } from 'react';

import Right from '../images/right.png';
import Left from '../images/left.png';


function AbschliessenBereiche(props) {
  const navigate = useNavigate();
  const authCtx = useContext(AuthContext);


  const userid=authCtx.userid;
  const awa=authCtx.ausfuehrungid;
  const awc=authCtx.checklistid;
  
  const [isLoading, setIsLoading]=useState(true);
  const [httpError, setHttpError]=useState();
  const [message, setMessage]=useState();
  const [bemerkung1, setBemerkung1]=useState();
  const [bemerkung2, setBemerkung2]=useState();
  const [bemerkung3, setBemerkung3]=useState();
  const [bemerkung4, setBemerkung4]=useState();
  const [bemerkung5, setBemerkung5]=useState();
  const [bem5Null, setbem5Null]=useState(true);
  const [bem6Null, setbem6Null]=useState(true);
  const [bem7Null, setbem7Null]=useState(true);
  const [bemerkung6, setBemerkung6]=useState();
  const [bemerkung7, setBemerkung7]=useState();
  const [questionNum, setQuestionNum]=useState(1);
  const [clickNum, setClickNum]=useState(0);
  let bemerkung1Ref=useRef();
  let bemerkung2Ref=useRef();
  let bemerkung3Ref=useRef();
  let bemerkung4Ref=useRef();
  let bemerkung5Ref=useRef();
  let bemerkung6Ref=useRef();
  let bemerkung7Ref=useRef();
  const [pageName, setPageName] = useState( 
    {name: 'Bereiche', 
    link1: '/bereiche', 
    link2: '/'});

  

  const clickItemHandler=(item)=>{
   let path=item.path;

   if (path!=='/') {
    navigate(path)
   } else {
    if (item.name==='Abschliessen'){

      //check if all checklists are fillted
      const responseData=JSON.parse(authCtx.bereichlist);
      const loadedItems=[];
    
      for (const key in responseData){
      //console.log(responseData[key]);
            loadedItems.push({
              id: responseData[key].lfdAuftragWartungsBereichID,
              name: responseData[key].ChecklistenBereich,
              description: responseData[key].Wert1,
              pos: responseData[key].PosNr,
              wert2:  responseData[key].Wert2,
              wert3:  responseData[key].Wert3,
              wert4:  responseData[key].Wert4,
              countFr: responseData[key].countFr,
              CountGeprueft: responseData[key].CountGeprueft,
        });
      }

     
      
      let num=loadedItems.filter(el=>el.countFr!==el.CountGeprueft).length;
      let totalnum=loadedItems.length;

      const responsepumpeData=JSON.parse(authCtx.pumpelist);
      const loadedpumpeItems=[];

      for (const key in responsepumpeData){
        //console.log(responseData[key]);
        loadedpumpeItems.push({
                id: responsepumpeData[key].lfdAuftragWartungsPumpeID,
                saved: responsepumpeData[key].saved,
          });
        }
  
        let numpumpe=loadedpumpeItems.filter(el=>Number(el.saved)===1).length;
        let totalnumpumpe=loadedpumpeItems.length;
     
        if (num>0) {
            alert(num+' von '+totalnum +' Bereichen nicht abgeschlossen')
          return;
          } else {
            if (totalnumpumpe-numpumpe>0){
              let pumpentocheck=totalnumpumpe-numpumpe;
              console.log(totalnumpumpe,numpumpe, pumpentocheck)
              alert(pumpentocheck+' Pumpen nicht abgeschlossen')
             
              return;
            } else {
              props.onAbschliessen();
              updatestatus(1)
              //console.log('Abschliessen')
              navigate('/checklist')

            }



          
          }
     
    } else {
     // props.onAbbrechen();
      console.log('Abbrechen')
      setMessage('Abbrechen? (Änderungen werden gelöscht.)');

    }
 
   
   }

  };
 
 useEffect(()=>{
console.log(questionNum)
//send url#
//console.log(status)

 
  //updatestatus(0)

   
 },[])


 
	   
function updatestatus(del){

  let url='https://tbsmobile.de/react-updatestatus.php?userid='+userid+'&awa='+awa+'&awc='+awc+'&del='+del;
  //console.log(url)
  const fetchItems=async()=>{
    setIsLoading(true);
    const response=await fetch(url, {
  // 15 is User Identifier
  
    headers: {
      'Content-Type': 'application/json'
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    // redirect: 'follow', // manual, *follow, error
    // referrerPolicy: 'no-referrer',
  });
  
  // console.log(response)
  if(!response.ok){
  throw new Error('Something went wrong!');
  }
  const responseData=await response.json();
  //transform to array
  //const loadedItems=[];
  
  console.log(responseData)
  setIsLoading(false);
  
  };
  
  
  fetchItems().catch(error=>{
  setIsLoading(false);
  setHttpError(error.message);
  });
  

}


const actionHandler=()=>{
    //Geben Sie Name des Kunden ein
//Geben Sie Firma Name ein
//Geben Sie valid Email Adresse ein
setMessage(null);

let awa=authCtx.ausfuehrungid;
let awc=authCtx.checklistid;
let userid=authCtx.userid;
let Materialverbrauch=bemerkung1;
let AnbietenLeistung=bemerkung2;
let Maengel=bemerkung3;
let Bemerkung=bemerkung4;
let Ansprechpartner=bemerkung5;
let Funktion=bemerkung6;
let Email=bemerkung7;
let url='https://tbsmobile.de/react-abschliessen.php?userid='+userid+'&awa='+awa+'&awc='+awc+'&Ansprechpartner='+Ansprechpartner+'&Funktion='+Funktion+'&Email='+Email;
if (Materialverbrauch && Materialverbrauch!==null & Materialverbrauch!==''){
  url=url+'&Materialverbrauch='+Materialverbrauch;
}
if (AnbietenLeistung && AnbietenLeistung!==null & AnbietenLeistung!==''){
  url=url+'&AnbietenLeistung='+AnbietenLeistung;
}
if (Maengel && Maengel!==null & Maengel!==''){
  url=url+'&Maengel='+Maengel;
}
if (Bemerkung && Bemerkung!==null & Bemerkung!==''){
  url=url+'&Bemerkung='+Bemerkung;
}

const storedAntworte = localStorage.getItem('antworte');
if (storedAntworte!==null) {
 
  let antwData1=JSON.parse(storedAntworte);

  console.log(antwData1)
  for (const z in antwData1){
 
    if (antwData1[z].updated===1) {
      savedata(antwData1[z].lfdAuftragWartungsAusfuehrungFrageID, antwData1[z])
    }
   
  }


}
const storedPumpeAntworte = localStorage.getItem('pumpelist');
if (storedPumpeAntworte!==null){
  let antwData1=JSON.parse(storedPumpeAntworte);
  for (const z in antwData1){
 
   
    savepumpedata(antwData1[z].lfdAuftragWartungsPumpeID, antwData1[z])
    
   
  }

}

updateausfuehrung(url);
updateantworte()
navigate('/melderliste')
/*
Zusätzlich ausgeführte Arbeiten/Materialverbrauch  --> Materialverbrauch
Monteur                                           Test User
AnbietenLeistung                              -->Folgende Leisungen sind anzubieten
Mängel, die bauseitig zu beheben sind          --> Maengel
Bemerkung (Intern)  -->Bemerkung
Anweisung durch Herrn/Frau        -> Ansprechpartner
Funktion
Email
StatusDatum


*/

 

 
  //'/bereiche'
  
}

async function updateausfuehrung (url){

  let response= await fetch(url, {
    // 15 is User Identifier
    
          headers: {
            'Content-Type': 'application/json'
            // 'Content-Type': 'application/x-www-form-urlencoded',
          },
          // redirect: 'follow', // manual, *follow, error
          // referrerPolicy: 'no-referrer',
      });
  
    
      if(!response.ok){
          throw new Error('Something went wrong!');
    
       }
}

const clearMessage=()=>{
  console.log('clearMessage');
  setMessage(null);
  
 
}





useEffect(()=>  {

  if (bemerkung5!==null && bemerkung5!=='' && bemerkung5){
    setbem5Null(false)
  }

},

[bemerkung5])

useEffect(()=>  {

  if (bemerkung6!==null && bemerkung6!=='' && bemerkung6){
    setbem6Null(false)
  }

},

[bemerkung6])

useEffect(()=>  {
  if (bemerkung7!==null && bemerkung7!=='' && bemerkung7){
    setbem7Null(false)
  }
  if (bemerkung7){
    
    let emailcount=bemerkung7.split('@').length-1;
    let commasplit=bemerkung7.split(',').length;
    let dotcount=bemerkung7.split('.').length-1;
  
    if ((Number(emailcount)===1 && emailcount===1 && dotcount===1) || (Number(emailcount)>0 &&emailcount===dotcount && emailcount===commasplit))
    {
     ///
    } else {
      setbem7Null(true)
    }
  
  
  }
  




},

[bemerkung7])


async function savedata(val1, array){
  let geprueft=array.Geprueft;
  let massnahmen=array.Massnahmen;
  let wert1=array.Wert1;
  let wert2=array.Wert2;
  let wert3=array.Wert3;
  let fkAuftragWartungsAusfuehrungID=array.fkAuftragWartungsAusfuehrungID;
  let fkAuftragWartungsChecklistenFrageID=array.fkAuftragWartungsChecklistenFrageID;
  
  
  //https://tbsmobile.de/react-updatechecklist.php?id=162592&awa=4&awcf=304140&gep=0&mass=mass&w1=0&w2=0&w3=0
  
  let url='https://tbsmobile.de/react-updatechecklist.php?id='+val1+'&awa='+fkAuftragWartungsAusfuehrungID+'&awcf='+fkAuftragWartungsChecklistenFrageID+'&gep='+geprueft;
  
  if (wert1.length>0 && wert1!==null){
    url=url+'&w1='+wert1;
  }
  
  if (wert2!==null && wert2.length>0){
    url=url+'&w2='+wert2;
  }
  
  if (wert3!==null && wert3.length>0){
    url=url+'&w3='+wert3;
  }
  
  if (massnahmen!==null && massnahmen.length>0){
    url=url+'&mass='+massnahmen;
  }
  
  
  
  let response= await fetch(url, {
    // 15 is User Identifier
    
          headers: {
            'Content-Type': 'application/json'
            // 'Content-Type': 'application/x-www-form-urlencoded',
          },
          // redirect: 'follow', // manual, *follow, error
          // referrerPolicy: 'no-referrer',
      });
  
     console.log(response)
  if(!response.ok){
    throw new Error('Something went wrong!');
  }
  
    } //end save


async function savepumpedata(val1, array){
  let fkAuftragWartungsAusfuehrungID=array.fkAuftragWartungsAusfuehrungID;
  let text='';
  let bemerkung=array.Bemerkung;
  if (bemerkung!==null && bemerkung.length>0){
      text=text+'&Bemerkung='+bemerkung;
  } 
   
      
      for (let i = 1; i < 11; i++) {
       // console.log(Number(array['DruckD'&i]), array.DruckD&i, array.DruckD+i, array.DruckD+{i}, array.DruckD&{i}, array['DruckD'+i])
        if (Number(array['DruckD'+i])>0){
          text=text+'&DruckD'+i+'='+Number(array['DruckD'+i]);
        } else {
          text=text+'&DruckD'+i+'=0';
        }
        if (Number(array['DruckS'+i])>0){
          text=text+'&DruckS'+i+'='+Number(array['DruckS'+i]);
        } else {
          text=text+'&DruckS'+i+'=0';
        }
        if (Number(array['StromE'+i])>0){
          text=text+'&StromE'+i+'='+Number(array['StromE'+i]);
        } else {
          text=text+'&StromE'+i+'=0';
        }
        if (Number(array['DrehD'+i])>0){
          text=text+'&DrehD'+i+'='+Number(array['DrehD'+i]);
        } else {
          text=text+'&DrehD'+i+'=0';
        }

   
        if (Number(array['DruckUD'+i])>0){
          text=text+'&DruckUD'+i+'='+Number(array['DruckUD'+i]);
        } else {
          text=text+'&DruckUD'+i+'=0';
        }
        if (Number(array['StromN'+i])>0){
          text=text+'&StromN'+i+'='+Number(array['StromN'+i]);
        } else {
          text=text+'&StromN'+i+'=0';
        }

        if (Number(array['StromNN'+i])>0){
          text=text+'&StromNN'+i+'='+Number(array['StromNN'+i]);
        } else {
          text=text+'&StromNN'+i+'=0';
        }
      
      }
  
    

    
    let url='https://tbsmobile.de/react-updatepumpe.php?id='+val1+'&awa='+fkAuftragWartungsAusfuehrungID+text;
//console.log(url)
    

let response= await fetch(url, {
  // 15 is User Identifier
  
        headers: {
          'Content-Type': 'application/json'
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        // redirect: 'follow', // manual, *follow, error
        // referrerPolicy: 'no-referrer',
    });

   console.log(response)
    if(!response.ok){
        throw new Error('Something went wrong!');
  
     }
    }



const clickFinalHandler=()=>{
  setClickNum(Number(clickNum)+1)
  if(bemerkung5===null || bemerkung5==='' || !bemerkung5){
    return;
  }
  if(bemerkung6===null || bemerkung6==='' || !bemerkung6){
    return;
  }
  //check fields
if (bemerkung7){
  let email=bemerkung7.includes('tr');
  let dot=bemerkung7.includes('@');
  let comma=bemerkung7.includes(',');
  let emailcount=bemerkung7.split('@').length-1;
  let commasplit=bemerkung7.split(',').length;
  let dotcount=bemerkung7.split('.').length-1;
  //console.log(email,dot, comma, emailcount, commasplit, dotcount)
  if ((Number(emailcount)===1 && emailcount===1 && dotcount===1) ||  (Number(emailcount)>0 && emailcount===dotcount && emailcount===commasplit))
  {
    if (bemerkung5!==null && bemerkung5!=='' && bemerkung5 && bemerkung6!==null && bemerkung6!=='' && bemerkung6 && bemerkung7!==null && bemerkung7!=='' && bemerkung7) {
      setMessage('Checkliste abschließen und Bericht erstellen?');
    }
  } else {
    alert('Email Prüfen')
    return;
  }


} else {
    alert('Email Prüfen')
    return;
}



    }

//Abbrechen
  
async function updateantworte (){

  let ausfuehrungid=authCtx.ausfuehrungid;
  let urlfrageitems='https://tbsmobile.de/react-fragenlist.php?id='+ausfuehrungid;
  const fetchFrageItems=async()=>{
    setIsLoading(true);
    const responseFrageItem=await fetch(urlfrageitems, {
  
      headers: {
        'Content-Type': 'application/json'
      },
     
  });
  
  if(!responseFrageItem.ok){
    throw new Error('Something went wrong!');
  }
  const responseFrageItemData=await responseFrageItem.json();
  //transform to array
  
  const loadedFrageItems=[];
  if (responseFrageItemData.length>0) {
   
      for (const key in responseFrageItemData){
          loadedFrageItems.push({
            lfdAuftragWartungsAusfuehrungFrageID: responseFrageItemData[key].lfdAuftragWartungsAusfuehrungFrageID,
            fkAuftragWartungsAusfuehrungID: responseFrageItemData[key].fkAuftragWartungsAusfuehrungID,
            fkAuftragWartungsChecklistenFrageID: responseFrageItemData[key].fkAuftragWartungsChecklistenFrageID,
            Geprueft: responseFrageItemData[key].Geprueft,
            Wert1:  responseFrageItemData[key].Wert1,
            Wert2:  responseFrageItemData[key].Wert2,
            Wert3:  responseFrageItemData[key].Wert3,
            Massnahmen:  responseFrageItemData[key].Massnahmen,
        });
  
      }
      authCtx.addAntworte(JSON.stringify(responseFrageItemData)); 
} else {
  authCtx.addAntworte(null);
}

  
  }
  
  fetchFrageItems().catch(error=>{
    //setIsLoading(false);
    //setHttpError(error.message);
    alert(error.message)
    console.log(error.message);
    });

}

const rightClickHandler=(event)=>{
  event.preventDefault();
  if (Number(questionNum)+1<6) {
    setQuestionNum(Number(questionNum)+1)
}
   
  }

  const leftClickHandler=(event)=>{
    event.preventDefault();
    if (Number(questionNum)>1) {
      setQuestionNum(Number(questionNum)-1)
  }


  }





  return (
   
    <div>
         {message && <Message onClose={clearMessage} onAction={actionHandler}>{message}</Message>}
      <Header items={pageName}/>
      <div className="wrapper">
      <section> 
       <div>
         <form  autoComplete='off'>
                      
             {questionNum===1 && (
                        <div  > 
                            <label  htmlFor='Name'>Zusätzlich ausgeführte Arbeiten/Materialverbrauch  </label>
                            <input  className='Name' id="Name" name="Name"
                                    type="text"
                                    value={bemerkung1}
                                    ref={bemerkung1Ref}
                                    onChange={event=>setBemerkung1(event.target.value)}
                              />
                         
                        </div>

             )} 
            {questionNum===2 && (
                        <div> 
                            <label htmlFor='Name'>Folgende Leistungen sind anzubieten</label>
                            <textarea className='Name' id="Name" name="Name"
                                    value={bemerkung2}
                                    ref={bemerkung2Ref}
                                    onChange={event=>setBemerkung2(event.target.value)}
                              />
                          
                        </div>

             )}
            {questionNum===3 && (
                        <div> 
                            <label htmlFor='Name'>Mängel, die bauseitig zu beheben sind</label>
                            <textarea className='Name' id="Name" name="Name"
                                  
                                    value={bemerkung3}
                                    ref={bemerkung3Ref}
                                    onChange={event=>setBemerkung3(event.target.value)}
                              />
                           
                        </div>

             )}  
            {questionNum===4 && (
                        <div> 
                            <label htmlFor='Name'>Bemerkung (Intern)</label>
                            <textarea className='Name' id="Name" name="Name"
                                    
                                    value={bemerkung4}
                                    ref={bemerkung4Ref}
                                    onChange={event=>setBemerkung4(event.target.value)}
                              />
                           
                        </div>

             )}  
            {questionNum===5 && (
                        <div> 
                            <div>
                                <label htmlFor='Anweisung'>Anweisung durch Herrn/Frau</label>
                                <input className='Anweisung' id="Anweisung" name="Anweisung"
                                    type="text"
                                    required
                                    value={bemerkung5}
                                    ref={bemerkung5Ref}
                                    onChange={event=>setBemerkung5(event.target.value)}
                                     />
                            </div>
                            <div>
                                <label htmlFor='Anweisung1'>Funktion in die Firma</label>
                                <input className='Anweisung1' id="Anweisung1" name="Anweisung1"
                                    type="text"
                                    required
                                    value={bemerkung6}
                                    ref={bemerkung6Ref}
                                    onChange={event=>setBemerkung6(event.target.value)}
                                     />
                            </div>
                            <div>
                                <label htmlFor='Anweisung2'>Email Ansprechpartner</label>
                                <textarea className='Anweisung2' id="Anweisung2" name="Anweisung2"
                                    
                                    required
                                    value={bemerkung7}
                                    ref={bemerkung7Ref}
                                    onChange={event=>setBemerkung7(event.target.value)}
                                     />
                                     <p>Mehrere E-Mail-Adressen mit , getrennt angeben.</p>
                            </div>
                           
                            <div>
                                {bem5Null===true && Number(clickNum)>0 && (<p >Geben Sie Name des Kunden ein</p>)}
                                {bem6Null===true && Number(clickNum)>0 && (<p>Geben Sie Firma Name ein</p>)}
                                {bem7Null===true && Number(clickNum)>0 && (<p>Geben Sie valid Email Adresse ein</p>)}
       
      </div>
                        </div>

             )}                        
                     
                    </form>
                  
                
                    
       </div>
     
      <hr className='h'></hr> 
     
      </section>
    
      {questionNum===5 && (  
                    <article className='Abb'>
                                      
                    </article >
                    )}
  <div className='footer-wrapper'>
 
      <div className='buttons' > 
      {questionNum===5 && (<button className='click'  onClick={clickFinalHandler} >Abschließen</button>) }
      {questionNum>1 && ( 
                          <div className="icon" onClick={leftClickHandler}>
                                       <i><img src={Left} alt=""/></i>
                          </div>)
      }                    
      {questionNum<5 && ( <div className="icon--1"  onClick={rightClickHandler}>
                              <i >
                                <img src={Right} alt="" />
                              </i>
                         </div>)
      }
          </div>
          </div>


</div>
    

         
    </div>
  )
}

export default AbschliessenBereiche
