
import React, { useState, useEffect,useContext } from 'react';
import Header from '../component/Header';

import {FaFilePdf} from "react-icons/fa";
import {FaRegFilePdf} from "react-icons/fa";
import AuthContext from '../store/auth-context';

//import {GrDocumentPdf} from "react-icons";
//import AuftragContext, { AuftragContextProvider } from '../store/auftrag-context';
import { GrDocumentPdf } from "react-icons/gr";
import { GrClose } from "react-icons/gr";
import NodataMessage from '../component/NodataMessage';

function Melderliste() {
    const pageName=
    {name: 'Melderliste', 
    link1: '/bereiche-datei', 
    link2: '/',
    hide1: 'no',
    hide2: 'no'};


const [fileURL, setFileURL]=useState();
  const [openPDF, setOpenPDF]=useState(false);
  const [statusid, setStatusid]=useState(0);
  const [isLoading, setIsLoading]=useState(true);
  const [httpError, setHttpError]=useState();
  const authCtx = useContext(AuthContext);
 // const auftragCtx=useContext(AuftragContext);

  
//ausführung status id
 
  let userid=authCtx.userid;
  
 

const clickHandler=()=>{

  let awa=authCtx.ausfuehrungid;
  let awc=authCtx.checklistid;
  
    const fetchItems=async()=>{
          setIsLoading(true);
          let url='https://tbsmobile.de/react-abschliessen.php?getlfd=1&awa='+awa+'&awc='+awc;
        
        const response=await fetch(url, {
    // 15 is User Identifier
    
          headers: {
            'Content-Type': 'application/json'
            // 'Content-Type': 'application/x-www-form-urlencoded',
          },
          // redirect: 'follow', // manual, *follow, error
          // referrerPolicy: 'no-referrer',
      });
     // console.log(url)
  if(!response.ok){
    throw new Error('Something went wrong!');
  }
  const responseData=await response.json();
//transform to array


 // console.log(responseData)
  if (Number(responseData)>0){
    setStatusid(Number(responseData))
    setFileURL('https://tbsmobile.de/pdf.php?id='+Number(responseData)+'&output=s');
    
  }
//  setAusfuehrung(loadedItems);
  setIsLoading(false);
//console.log(loadedItems);
};


fetchItems().catch(error=>{
      setIsLoading(false);
      setHttpError(error.message);
     });
  
    
  if (!httpError  ){
    setOpenPDF(true)
  }
 
  
}

const closePDF=()=>{
  setOpenPDF(false)
  setFileURL('');
}

useEffect(()=>{
  console.log(statusid)
  authCtx.addAusfuehrungStatusid(statusid);
 // authCtx.addAusfuehrungstatusid(statusid)

},
[statusid])
//const fileURL= "https://tbsmobile.de/pdf.php?id=9&output=s";

//nach anklicken eines Auftrags, frage ob Wartung begonnen werden soll? => Ja, weiter zu Seite 2

//"https://tbsmobile.de/pdf.php?id=9&output=s"


/*When document gets loaded successfully*/



  return (

    <div>
     <Header items={pageName}/>
     <div className="wrapper">
     <section >
                            {openPDF===false && ( <div > 
                                    <button className='pdf' onClick={clickHandler} ><p>PDF Vorschau</p> <GrDocumentPdf/></button>
                              </div>)}

                       {openPDF===true && (<div id="pdf">
                              <div className="inAppBrowserWrap">
                              {(!isLoading && !httpError) && (<iframe src={fileURL} width="100%" height="600px"></iframe>)}
                              {isLoading && (<NodataMessage>Loading...</NodataMessage>)}
                              {httpError && ( <NodataMessage>{httpError}</NodataMessage>)}
	                                          <div className="inappbrowser-app-bar">
		                                             <div className="inappbrowser-app-bar-inner">
			                                                <div className="app-bar-action action-close" onClick={closePDF}>close<GrClose/></div>
		                                                </div>
	                                            </div>
                                </div>

                              
                            
                        </div>)}
                  </section>
      </div>
                  
                  
                 
  
    </div>
  
  )
}

export default Melderliste
