
import { Link } from 'react-router-dom';

const AusfuehrungItem=(props)=>{
   


    return (
        
  
        
        <div className='linkliste' onClick={props.onClick}>
            <div className='linktext'>
                    {props.description} {props.bz} {props.plz} {props.ort} {props.date} 
            
            </div>
            <div className='linkbutton'>
            
            <img src={require('../images/weiter.svg').default} alt='weiter' />
            </div>
           
        </div>
        
  
   );
};

export default AusfuehrungItem;