
import Header from '../component/Header';
import React from 'react';
import { Fragment } from "react";
import Message from '../component/Message/Message';
import AuthContext from '../store/auth-context';
import PumpeItemDetail from '../component/PumpeItemDetail';
import PumpeForm from '../component/PumpeForm';
import { useRef, useState, useEffect, useContext  } from 'react';
import { useNavigate } from 'react-router-dom';

const PumpeDetail=()=>{
  const navigate = useNavigate();
  const form = document.getElementById('pumpeform');
 
    const pageName=
    {name: 'Pumpe', 
    link1: '/pumpen', 
    link2: '/'};

   
    const bemerkungRef=useRef();
    const [bemerkungWert, setBemerkungWert]=useState('');
    const [message, setMessage]=useState();

    const authCtx = useContext(AuthContext);
    let  pumpelist=JSON.parse(authCtx.pumpelist);
    let pumpeList=pumpelist.filter(el=>el.lfdAuftragWartungsPumpeID===authCtx.pumpeid);
    let pumpetype;
    if (Number(pumpeList[0].Protokolltyp)===1){
       
        if (Number(pumpeList[0].Motor)===1){
            pumpetype=1;
        } else {
            pumpetype=3;
        }
      } else if (Number(pumpeList[0].Protokolltyp)===2) {
        pumpetype=2;
      }
   
    useEffect(()=>{
        pumpelist=JSON.parse(authCtx.pumpelist);
        pumpeList=pumpelist.filter(el=>el.lfdAuftragWartungsPumpeID===authCtx.pumpeid);
           
        
    let array=[];

    for (let i = 1; i < 11; i++) {
        let newRow={};
        newRow.i=i;
        newRow.q=pumpeList[0]['Q'+i]; //props['q'+i];
        newRow.a=pumpeList[0]['A'+i];
        newRow.d=pumpeList[0]['D'+i];
        newRow.pi=pumpeList[0]['PI'+i];
        newRow.druckd=pumpeList[0]['DruckD'+i];
        newRow.drucks=pumpeList[0]['DruckS'+i];
        newRow.strome=pumpeList[0]['StromE'+i];
        newRow.stromn=pumpeList[0]['StromN'+i];
        newRow.stromnn=pumpeList[0]['StromNN'+i];
        array.push(newRow);
       
      }
   
     

      }, [authCtx.pumpelist])
 
   //=1 - Pumpe mit Motor Elektro  pumpeList[0].Protokolltyp=1 && Motor 1
        //Druck Manometer Druckleit.
        //Druck Manometer Saugleit.
        //Druck Pumpe vorh. (bar)
        //Stromaufnahm. E-Pumpe (A)
    
   //=2 - Unterwasserpumpe   pumpeList[0].Protokolltyp=2

    //=3 - Pumpe mit Motor Diesel  pumpeList[0].Protokolltyp=1 && Motor 1
            //Druck Manometer Druckleit.
            //Druck Manometer Saugleit.
            //Druck Pumpe vorh. (bar)
            //Drehzahl D-Pumpe (1/min)
    
    //Number(pumpeList[0].Protokolltyp);

    
   
    
    useEffect(()=>{
        if (pumpeList[0].Bemerkung!==null){
            setBemerkungWert(pumpeList[0].Bemerkung)
        }
        
      }, [])
   // const [pumpeList, setPumpeList]=useState(pumpefiltered);
//console.log(pumpeList)


useEffect(()=>{
    // console.log(textValue)
   const timer=setTimeout(()=>{
     if (bemerkungWert=== bemerkungRef.current.value){
      
   // console.log(pumpelist)
 // authCtx.addPumpelist(JSON.stringify(pumpelist))
   //updatePumpeList(pumpelist)
    
     //props.onCheckItem(newRow);
    // antworte(newRow)
     }
   }, 500);
   return ()=>{
     clearTimeout(timer);
   };
   
   },[bemerkungWert, bemerkungRef])


    let array=[];

    for (let i = 1; i < 11; i++) {
        let newRow={};
        newRow.i=i;
        newRow.q=pumpeList[0]['Q'+i]; //props['q'+i];
        newRow.a=pumpeList[0]['A'+i];
        newRow.d=pumpeList[0]['D'+i];
        newRow.pi=pumpeList[0]['PI'+i];
        newRow.druckd=pumpeList[0]['DruckD'+i];
        newRow.drucks=pumpeList[0]['DruckS'+i];
        newRow.strome=pumpeList[0]['StromE'+i];
        newRow.stromn=pumpeList[0]['StromN'+i];
        newRow.stromnn=pumpeList[0]['StromNN'+i];
        newRow.drehd=pumpeList[0]['DrehD'+i];
        newRow.druckud=pumpeList[0]['DruckUD'+i];
        array.push(newRow);
       
      }
      //console.log(array)
     // console.log(pumpeList[0])
//}, [])


function funcSubmit(){
  
  console.log('submit')
  console.log(form)
  pumpeAbschliessenHandler()
}

async function savepumpedata(val1, array){
  let fkAuftragWartungsAusfuehrungID=array.fkAuftragWartungsAusfuehrungID;
  let text='';
  let bemerkung=array.Bemerkung;
  if (bemerkung!==null && bemerkung.length>0){
      text=text+'&Bemerkung='+bemerkung;
  } 
   
      
      for (let i = 1; i < 11; i++) {
       // console.log(Number(array['DruckD'&i]), array.DruckD&i, array.DruckD+i, array.DruckD+{i}, array.DruckD&{i}, array['DruckD'+i])
        if (Number(array['DruckD'+i])>0){
          text=text+'&DruckD'+i+'='+Number(array['DruckD'+i]);
        } else {
          text=text+'&DruckD'+i+'=0';
        }
        if (Number(array['DruckS'+i])>0){
          text=text+'&DruckS'+i+'='+Number(array['DruckS'+i]);
        } else {
          text=text+'&DruckS'+i+'=0';
        }
        if (Number(array['StromE'+i])>0){
          text=text+'&StromE'+i+'='+Number(array['StromE'+i]);
        } else {
          text=text+'&StromE'+i+'=0';
        }
        if (Number(array['DrehD'+i])>0){
          text=text+'&DrehD'+i+'='+Number(array['DrehD'+i]);
        } else {
          text=text+'&DrehD'+i+'=0';
        }

   
        if (Number(array['DruckUD'+i])>0){
          text=text+'&DruckUD'+i+'='+Number(array['DruckUD'+i]);
        } else {
          text=text+'&DruckUD'+i+'=0';
        }
        if (Number(array['StromN'+i])>0){
          text=text+'&StromN'+i+'='+Number(array['StromN'+i]);
        } else {
          text=text+'&StromN'+i+'=0';
        }

        if (Number(array['StromNN'+i])>0){
          text=text+'&StromNN'+i+'='+Number(array['StromNN'+i]);
        } else {
          text=text+'&StromNN'+i+'=0';
        }
      
      }
  
    

    
    let url='https://tbsmobile.de/react-updatepumpe.php?id='+val1+'&awa='+fkAuftragWartungsAusfuehrungID+text;
//console.log(url)
    

let response= await fetch(url, {
  // 15 is User Identifier
  
        headers: {
          'Content-Type': 'application/json'
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        // redirect: 'follow', // manual, *follow, error
        // referrerPolicy: 'no-referrer',
    });

   console.log(response)
if(!response.ok){
  throw new Error('Something went wrong!');
  
}
}


function editPumpelist(type){
  
  const responseData=JSON.parse(authCtx.pumpelist);

  let updatedRow=responseData.filter(el=>el.lfdAuftragWartungsPumpeID===authCtx.pumpeid);
//let updatedRowIndex=bereichlistData.findIndex(el=>el.lfdAuftragWartungsBereichID===authCtx.bereichid);
// console.log(updatedRow)
// console.log(bereichlistData[updatedRowIndex])
if (updatedRow){
    if (type===1) {
        updatedRow[0].saved=1;
      }
       
        updatedRow[0]['fkAuftragWartungsAusfuehrungID']=Number(authCtx.ausfuehrungid);
        updatedRow[0]['fkAuftragWartungsPumpeID']=Number(authCtx.pumpeid);
        updatedRow[0]['Bemerkung']=bemerkungWert;
    for (let i = 1; i < 11; i++) {
      if (pumpetype!==2){
        var inputval = document.getElementById('dr'+i);
        if (inputval.value!==null &&  inputval.value!==''){
          //let updatedarr=array.filter(el=>el.i===i);
          updatedRow[0]['DruckD'+i]=Number(inputval.value);
          //updatedpumpe[0]['DruckD'+i]=Number(inputval.value);
          //console.log(inputval.value, 'dr'+i)
        }
        inputval = document.getElementById('sa'+i);
        if (inputval.value!==null &&  inputval.value!==''){
          updatedRow[0]['DruckS'+i]=Number(inputval.value);
         
          //updatedpumpe[0]['DruckS'+i]=Number(inputval.value);
        }
        if (pumpetype===1) {
          inputval = document.getElementById('strome'+i);
          if (inputval.value!==null &&  inputval.value!==''){
            updatedRow[0]['StromE'+i]=Number(inputval.value);
            
            //updatedpumpe[0]['StromE'+i]=Number(inputval.value);
          }
        }
       
        if (pumpetype===3) {
          inputval = document.getElementById('drehd'+i);
          if (inputval.value!==null &&  inputval.value!==''){
            updatedRow[0]['DrehD'+i]=Number(inputval.value);
           
            //updatedpumpe[0]['DrehD'+i]=Number(inputval.value);
          }
        }
      }
     
      if (pumpetype===2) {
        inputval = document.getElementById('druckud'+i);
        if (inputval.value!==null &&  inputval.value!==''){
          updatedRow[0]['DruckUD'+i]=Number(inputval.value);
        }
        inputval = document.getElementById('stromn'+i);
        if (inputval.value!==null &&  inputval.value!==''){
          updatedRow[0]['StromN'+i]=Number(inputval.value);
        }
        inputval = document.getElementById('stromnn'+i);
        if (inputval.value!==null &&  inputval.value!==''){
          updatedRow[0]['StromNN'+i]=Number(inputval.value);
        }
      }
      
      
    }

}


authCtx.addPumpelist(JSON.stringify(responseData))
//authCtx.addBereichlist(JSON.stringify(bereichlistData));



//fragenList.id -- bereich id CountGeprueft countFr

}


const actionHandler=()=>{
 //saved=1
  editPumpelist(1)

 


  setMessage(null);
  navigate('/pumpen');
}

const pumpeAbschliessenHandler=()=>{
  setMessage('Pumpen abschließen?')
    
/*
   // -------------------------
    let pumpelist=JSON.parse(authCtx.pumpelist);
    const pumpeid=authCtx.pumpeid;
    let updatedpumpe=pumpelist.filter(el=>el.lfdAuftragWartungsPumpeID===pumpeid)
    console.log(updatedpumpe)
    
    for (let i = 1; i < 11; i++) {
      var inputval = document.getElementById('dr'+i);
      if (inputval.value!==null &&  inputval.value!==''){
        let updatedarr=array.filter(el=>el.i===i);
        updatedarr[0]['druckd']=Number(inputval.value);
        //updatedpumpe[0]['DruckD'+i]=Number(inputval.value);
        console.log(inputval.value, 'dr'+i)
      }
      inputval = document.getElementById('sa'+i);
      if (inputval.value!==null &&  inputval.value!==''){
        let updatedarr=array.filter(el=>el.i===i);
        updatedarr[0]['drucks']=Number(inputval.value);
        console.log(inputval.value, 'sa'+i)
        //updatedpumpe[0]['DruckS'+i]=Number(inputval.value);
      }
      inputval = document.getElementById('strome'+i);
      if (inputval.value!==null &&  inputval.value!==''){
        let updatedarr=array.filter(el=>el.i===i);
        updatedarr[0]['strome']=Number(inputval.value);
        console.log(inputval.value, 'sa'+i)
        //updatedpumpe[0]['StromE'+i]=Number(inputval.value);
      }
      inputval = document.getElementById('drehd'+i);
      if (inputval.value!==null &&  inputval.value!==''){
        let updatedarr=array.filter(el=>el.i===i);
        updatedarr[0]['drehd']=Number(inputval.value);
        console.log(inputval.value, 'sa'+i)
        //updatedpumpe[0]['DrehD'+i]=Number(inputval.value);
      }
      
    }
    console.log(array)
    updatedpumpe[0]['Bemerkung']=bemerkungWert;
    updatedpumpe[0]['fkAuftragWartungsAusfuehrungID']=Number(authCtx.ausfuehrungid)
    
    updatedpumpe[0]['updated']=1;

    //SELECT * FROM `tblauftragwartungsausfuehrungpumpe`
    //props.onAbschliessen()
    */
}


const clearMessage=()=>{
 
  editPumpelist(0)
  setMessage(null);
  navigate('/pumpen');
}
//className="container"
    //className="com-padding"
  const pumpeTable= (
    <Fragment>
        <div className="container"> 
        <div className="com-padding"> 
        
        {pumpetype===1 && (
            <table>
            <thead>
                <tr> 
                    <th>Nr</th>
                    <th>Q (l/mm)</th>
                    <th>% Anzeige Messgerät</th>
                    <th>Druck It. Pumpenkennlinie (bar)</th>
                    <th>Druck Manometer Druckleit.</th>
                    <th>Druck Manometer Saugleit.</th>
                    <th>Druck Pumpe vorh. (bar)</th>
                    <th>Stromaufnahm. E-Pumpe (A)</th>
                </tr>
            </thead>
            <tbody>
            {  (
            array.map((pumpe)=> (
            <PumpeForm 
                key={pumpe.i}
                id={pumpe.i}
                q={pumpe.q}
                a={pumpe.a}
                 d={pumpe.d}
                 pi={pumpe.pi}
                 drehd={pumpe.drehd}
                 druckud={pumpe.druckud}
                 druckd={pumpe.druckd}
                 drucks={pumpe.drucks}
                 strome={pumpe.strome}
                 stromn={pumpe.stromn}
                 stromnn={pumpe.stromnn}
                 pumpetype={1}
                
        
              
          />))

    )}
            </tbody>
           </table>
        )
    }
      {pumpetype===2 && (
            <table>
            <thead>
                <tr> 
                    <th>NR</th>
                    <th>Q (l/mm)</th>
                    <th>% Anzeige Messgerät</th>
                    <th>Druck It. Pumpenkennlinie (bar)</th>
                    <th>P1 Druckdiff. (siehe Erklärung unten)</th>
                    <th>P2 Druck Manometer Druckleitung (bar)</th>
                    <th>P vorh. (P1+P2) Anfangsdruck U-pumpe (bar)</th>
                    <th>Strom-aufnahme Schaltschrank Netz (A)</th>
                    <th>Strom-aufnahme Schaltschrank Notnetz (A)</th>
                </tr>
            </thead>
            <tbody>
            {  (
            array.map((pumpe)=> (
            <PumpeForm 
                  	  key={pumpe.i}
                      id={pumpe.i}
                      q={pumpe.q}
                      a={pumpe.a}
                      d={pumpe.d}
                      pi={pumpe.pi}
                      drehd={pumpe.drehd}
                      druckud={pumpe.druckud}
                      druckd={pumpe.druckd}
                      drucks={pumpe.drucks}
                      strome={pumpe.strome}
                      stromn={pumpe.stromn}
                      stromnn={pumpe.stromnn}
                      pumpetype={2}
          />))

    )}
            </tbody>
           </table>
        )
    }
     {pumpetype===3 && (
            <table>
            <thead>
                <tr> 
                    <th>NR</th>
                    <th>Q (l/mm)</th>
                    <th>% Anzeige Messgerät</th>
                    <th>Druck It. Pumpenkennlinie (bar)</th>
                    <th>Druck Manometer Druckleit.</th>
                    <th>Druck Manometer Saugleit.</th>
                    <th>Druck Pumpe vorh. (bar)</th>
                    <th>Drehzahl D-Pumpe (l/min)</th>
                </tr>
            </thead>
            <tbody>
                 {(
                  array.map((pumpe)=> (
                                <PumpeForm 
                                            key={pumpe.i}
                                            id={pumpe.i}
                                            q={pumpe.q}
                                            a={pumpe.a}
                                            d={pumpe.d}
                                            pi={pumpe.pi}
                                            druckd={pumpe.druckd}
                                            drucks={pumpe.drucks}
                                            drehd={pumpe.drehd}
                                            druckud={pumpe.druckud}
                                            pumpetype={3}
                                            strome={pumpe.strome}
                                            stromn={pumpe.stromn}
                                            stromnn={pumpe.stromnn}
                                  />
                              ))

                  )}
            </tbody>
           </table>
        )
    }


   </div>
   </div>
</Fragment>);
    
    return (
        <div>
          {message && <Message onClose={clearMessage} onAction={actionHandler}>{message}</Message>}
            <Header items={pageName}/>
            <div className="wrapper">
            <section >
              <div> 
            {
                pumpeList.map((pumpe)=> (
                        <PumpeItemDetail 
                              key={pumpe.lfdAuftragWartungsPumpeID}
                              id={pumpe.lfdAuftragWartungsPumpeID}
                              name={pumpe.Typ}
                              nr={pumpe.Nr}
                              description={pumpe.Fabrikat}
                              protokolltyp={pumpe.Protokolltyp}
                              probierleitung={pumpe.Probierleitung}
                              motor={pumpe.Motor}
                              betriebspunkt1={pumpe.Betriebspunkt_LiMin}
                              betriebspunkt2={pumpe.Betriebspunkt_bar}
                              schaltschranknetz1={pumpe.SchaltschrankNetz}
                              schaltschranknetz2={pumpe.SchaltschrankNotnetz}
                          />))
              }
          
          </div>
           <div> 
           
                    <form id="pumpeform" >
                        {pumpeTable}
                        <div> 
                            <p >Bemerkung:</p>
                            <textarea  className='Abschliessen' id="Abschliessen" name="Abschliessen"
                                   
                                    value={bemerkungWert}
                                    ref={bemerkungRef}
                                    onChange={event=>setBemerkungWert(event.target.value)}
                              />
                            <hr className='h'></hr> 
                        </div>
                       
                    </form>
                    </div>
            </section>
                  <div className="footer-wrapper"> 
                        <div className="bnt-right"> 
                                <button  onClick={pumpeAbschliessenHandler}>Weiter</button>
                          </div>
                      </div>
        </div>
        </div>
      )
};

export default PumpeDetail;