import Yes from '../images/yes.png';
import No from '../images/no.png';

import { useRef, useState, useContext, useEffect } from 'react';
import AuthContext from '../store/auth-context';
import { Fragment } from "react";

import {BiMessageRoundedError} from "react-icons/bi";
  
const FrageForm2 = (props) => {
  //console.log(props)
 //const {loadedprops}=props;
 

  const [geprueft, setGeprueft]=useState();
  const [wert3Value, setwert3Value]=useState(props.antw3);
  const [wert2Value, setwert2Value]=useState(props.antw2);
  const [wert1Value, setwert1Value]=useState(props.antw1);
  const [textValue, setTextValue]=useState(props.massnahmen);
  const authCtx = useContext(AuthContext);
  const [antworteList, setAntworteList]=useState([]);
  const label1=props.wertbeschriftung1;
  const label2=props.wertbeschriftung2;
  const label3=props.wertbeschriftung3;

  if (antworteList.length>0){
    authCtx.addAntworte(JSON.stringify(antworteList))
   }

  //const textInputRef=useRef();
  const wert1InputRef=useRef();
  const wert2InputRef=useRef();
  const wert3InputRef=useRef();

  /*
  useEffect(()=>{
    // console.log(textValue)
   const timer=setTimeout(()=>{
     if (textValue=== textInputRef.current.value){
      if (props.geprueft==='0' && (textValue==='' || textValue===null)  ){
        let alertText='Maßnahmen eingeben';
        alert(alertText);
        return;
      }
    //if (textValue!=='' && textValue!==null ) {
        let newRow={};
        let lfdAuftragWartungsAusfuehrungFrageID=0;
        let responseData1=JSON.parse(authCtx.antworte);
        let filteredresponseData=responseData1.filter(el=>el.fkAuftragWartungsChecklistenFrageID===props.id);
        if (filteredresponseData.length>0){
            lfdAuftragWartungsAusfuehrungFrageID=filteredresponseData[0].lfdAuftragWartungsAusfuehrungFrageID
        }

        newRow.lfdAuftragWartungsAusfuehrungFrageID=lfdAuftragWartungsAusfuehrungFrageID;
        newRow.fkAuftragWartungsAusfuehrungID=props.ausfuehrung;
        newRow.fkAuftragWartungsChecklistenFrageID=props.id;
        newRow.Wert1=props.antw1;
        newRow.Wert2=props.antw2;
        newRow.Wert3=props.antw3;
        newRow.Geprueft=props.geprueft;
        newRow.Massnahmen=textValue;
        newRow.updated=1;
        //console.log(newRow)
        props.onCheck(newRow)
// props.onCheckItem(newRow);
        antworte(newRow)

//}
  
   //  changedValues();
     //props.onCheckItem(newRow);
    // antworte(newRow)
     }
   }, 500);
   return ()=>{
     clearTimeout(timer);
   };
   
   },[textInputRef, textValue])

*/

     
  useEffect(()=>{
    // console.log(textValue)
   const timer=setTimeout(()=>{
     if (wert1Value=== wert1InputRef.current.value){
      if (props.geprueft!==null ){
        if (label1!==null && props.wert1!=='0' && (wert1Value==='' || wert1Value===null)  ){
          let alertText=label1+' eingeben';
          alert(alertText);
          return;
        }
      }
         
          if (wert1Value!=='' && wert1Value!==null) {
              let newRow={};
              let lfdAuftragWartungsAusfuehrungFrageID=0;
              let responseData1=JSON.parse(authCtx.antworte);
              let filteredresponseData=responseData1.filter(el=>el.fkAuftragWartungsChecklistenFrageID===props.id);
              if (filteredresponseData.length>0){
                  lfdAuftragWartungsAusfuehrungFrageID=filteredresponseData[0].lfdAuftragWartungsAusfuehrungFrageID
              }

              newRow.lfdAuftragWartungsAusfuehrungFrageID=lfdAuftragWartungsAusfuehrungFrageID;
              newRow.fkAuftragWartungsAusfuehrungID=props.ausfuehrung;
              newRow.fkAuftragWartungsChecklistenFrageID=props.id;
              newRow.Wert1=wert1Value;
              newRow.Wert2=props.antw2;
              newRow.Wert3=props.antw3;
              newRow.Geprueft=props.geprueft;
              newRow.Massnahmen=props.massnahmen;
              newRow.updated=1;
         //     console.log(newRow)
  props.onCheck(newRow)
 // props.onCheckItem(newRow);
  antworte(newRow)

}
      

   //  changedValues();
     //props.onCheckItem(newRow);
    // antworte(newRow)
     }
   }, 500);
   return ()=>{
     clearTimeout(timer);
   };
   
   },[wert1Value, wert1InputRef])


   useEffect(()=>{
    // console.log(textValue)
   const timer=setTimeout(()=>{
     if (wert2Value=== wert2InputRef.current.value){
   //  changedValues();
     //props.onCheckItem(newRow);
    // antworte(newRow)
    if (props.geprueft!==null){
      if (label2!==null && props.wert2!=='0' && (wert2Value==='' || wert2Value===null)  ){
        let alertText=label2+' eingeben';
        alert(alertText);
        return;
      }
    }
  
  if (wert2Value!=='' && wert2Value!==null) {
      let newRow={};
      let lfdAuftragWartungsAusfuehrungFrageID=0;
      let responseData1=JSON.parse(authCtx.antworte);
      let filteredresponseData=responseData1.filter(el=>el.fkAuftragWartungsChecklistenFrageID===props.id);
      if (filteredresponseData.length>0){
          lfdAuftragWartungsAusfuehrungFrageID=filteredresponseData[0].lfdAuftragWartungsAusfuehrungFrageID
      }

      newRow.lfdAuftragWartungsAusfuehrungFrageID=lfdAuftragWartungsAusfuehrungFrageID;
      newRow.fkAuftragWartungsAusfuehrungID=props.ausfuehrung;
      newRow.fkAuftragWartungsChecklistenFrageID=props.id;
      newRow.Wert1=props.antw1;
      newRow.Wert2=wert2Value;
      newRow.Wert3=props.antw3;
      newRow.Geprueft=props.geprueft;
      newRow.Massnahmen=props.massnahmen;
      newRow.updated=1;
    //  console.log(newRow)
      props.onCheck(newRow)
// props.onCheckItem(newRow);
      antworte(newRow)

      }

     }
   }, 500);
   return ()=>{
     clearTimeout(timer);
   };
   
   },[wert2Value, wert2InputRef])

   
   useEffect(()=>{
    // console.log(textValue)
   const timer=setTimeout(()=>{
     if (wert3Value=== wert3InputRef.current.value){
      if (props.geprueft!==null){
        if (label3!==null && props.wert3!=='0' && (wert3Value==='' || wert3Value===null)  ){
          let alertText=label3+' eingeben';
          alert(alertText);
          return;
        }
      }
     
    if (wert3Value!=='' && wert3Value!==null) {
        let newRow={};
        let lfdAuftragWartungsAusfuehrungFrageID=0;
        let responseData1=JSON.parse(authCtx.antworte);
        let filteredresponseData=responseData1.filter(el=>el.fkAuftragWartungsChecklistenFrageID===props.id);
        if (filteredresponseData.length>0){
            lfdAuftragWartungsAusfuehrungFrageID=filteredresponseData[0].lfdAuftragWartungsAusfuehrungFrageID
        }
  
        newRow.lfdAuftragWartungsAusfuehrungFrageID=lfdAuftragWartungsAusfuehrungFrageID;
        newRow.fkAuftragWartungsAusfuehrungID=props.ausfuehrung;
        newRow.fkAuftragWartungsChecklistenFrageID=props.id;
        newRow.Wert1=props.antw1;
        newRow.Wert2=props.antw2;
        newRow.Wert3=wert3Value;
        newRow.Geprueft=props.geprueft;
        newRow.Massnahmen=props.massnahmen;
        newRow.updated=1;
        console.log(newRow)
        props.onCheck(newRow)
  // props.onCheckItem(newRow);
        antworte(newRow)
  
        }
     //changedValues();
     //props.onCheckItem(newRow);
    // antworte(newRow)
     }
   }, 500);
   return ()=>{
     clearTimeout(timer);
   };
   
   },[wert3Value, wert3InputRef])
 
  


  useEffect(()=>{
   //   console.log('props')
    //  textInputRef.current.value=props.massnahmen;               //08.02.2023
      wert1InputRef.current.value=props.antw1;
      wert2InputRef.current.value=props.antw2;
      wert3InputRef.current.value=props.antw3;
  },[])


  
  for (let i = 0; i < props.length; i++) {
   
    let newRow={};
    newRow.id=props.id;
    newRow.pos=props.pos; //props['q'+i];
    newRow.name=props.name;
   /*
    if (filteredAntworte.length>0){
      newRow.geprueft=filteredAntworte.Geprueft;
     } else {
      newRow.geprueft=null;
     }
     */
   
  }

 // console.log(arrAntworte, 1);


    const clickYesHandler=(event)=>{
      event.preventDefault();

      let wert1Input=wert1InputRef.current.value;
      let wert2Input=wert2InputRef.current.value;
      let wert3Input=wert3InputRef.current.value;
      if(wert1InputRef.current.value==='0'){
          wert1Input='';
      }
      if(wert2InputRef.current.value==='0'){
          wert2Input='';
      }
      if(wert3InputRef.current.value==='0'){
          wert3Input='';
      }
      if (label1!==null && wert1Input.length===0 && props.wert1!=='0'){
        let alertText=label1+' eingeben';
        alert(alertText);
        return;
      }
      if (label2!==null && wert2Input.length===0 && props.wert2!=='0'){
        let alertText=label2+' eingeben';
        alert(alertText);
        return;
      }
      if (label3!==null && wert3Input.length===0 && props.wert3!=='0'){
        let alertText=label3+' eingeben';
        alert(alertText);
        return;
      }
      

        
         // console.log('yes');
          let newRow={};
          let lfdAuftragWartungsAusfuehrungFrageID=0;
          let responseData1=JSON.parse(authCtx.antworte);

          let filteredresponseData=responseData1.filter(el=>el.fkAuftragWartungsChecklistenFrageID===props.id);
          if (filteredresponseData.length>0){
            lfdAuftragWartungsAusfuehrungFrageID=filteredresponseData[0].lfdAuftragWartungsAusfuehrungFrageID
          }
      
          newRow.lfdAuftragWartungsAusfuehrungFrageID=lfdAuftragWartungsAusfuehrungFrageID;
          newRow.fkAuftragWartungsAusfuehrungID=props.ausfuehrung;
          newRow.fkAuftragWartungsChecklistenFrageID=props.id;
          newRow.Wert1=wert1Input;
          newRow.Wert2=wert2Input;
          newRow.Wert3=wert3Input;
          newRow.Geprueft='1';
          newRow.Massnahmen=textValue;
          newRow.updated=1;
        //  console.log(newRow)
          setGeprueft('yes')
          props.onCheck(newRow)
         // props.onCheckItem(newRow);
          antworte(newRow)
    }

    const clickNoHandler2=(event)=>{
      event.preventDefault();
      props.onShowTextInput();
    }

    const clickEditHandler=(event)=>{
      event.preventDefault();
      props.onEditTextInput();
    }

    /*
    const clickNoHandler=(event)=>{
      event.preventDefault();
      let wert1Input=wert1InputRef.current.value;
      let wert2Input=wert2InputRef.current.value;
      let wert3Input=wert3InputRef.current.value;
      if(wert1InputRef.current.value==='0'){
        wert1Input='';
      }
      if(wert2InputRef.current.value==='0'){
        wert2Input='';
      }
      if(wert3InputRef.current.value==='0'){
        wert3Input='';
      }
      let newRow={};
      let bemerkung=textInputRef.current.value;
      if (bemerkung.length>0){
      //  console.log('no');

        if (label1!==null && (wert1Input.length===0 || wert1Input===null) && props.wert1!=='0'){
          let alertText=label1+' eingeben';
          alert(alertText);
       return;
        }
        if (label2!==null && wert2Input.length===0 && props.wert2!=='0'){
          let alertText=label2+' eingeben';
          alert(alertText);
          return;
        }
        if (label3!==null && wert3Input.length===0 && props.wert3!=='0'){
          let alertText=label3+' eingeben';
          alert(alertText);
          return;
        }

        let lfdAuftragWartungsAusfuehrungFrageID=0;
        let responseData1=JSON.parse(authCtx.antworte);
        let filteredresponseData=responseData1.filter(el=>el.fkAuftragWartungsChecklistenFrageID===props.id);
        if (filteredresponseData.length>0){
          lfdAuftragWartungsAusfuehrungFrageID=filteredresponseData[0].lfdAuftragWartungsAusfuehrungFrageID
        }
    
        newRow.lfdAuftragWartungsAusfuehrungFrageID=lfdAuftragWartungsAusfuehrungFrageID;
        newRow.fkAuftragWartungsAusfuehrungID=props.ausfuehrung;
        newRow.fkAuftragWartungsChecklistenFrageID=props.id;
        newRow.Wert1=wert1Input;
        newRow.Wert2=wert2Input;
        newRow.Wert3=wert3Input;
        newRow.Geprueft='0';
        newRow.Massnahmen=textValue;
        newRow.updated=1;
        setGeprueft('no')
        props.onCheck(newRow)
       // props.onCheckItem(newRow);
        antworte(newRow)
        
      } else {
        alert('Maßnehmen eingeben')
      }
    
    }
*/
    
function antworte(row){
 // console.log(row)
  //  console.log('antworte');
  let arr=[];
  
  if (authCtx.antworte===null){
  arr.push(row);
  authCtx.addAntworte(JSON.stringify(arr))
  } else {
  let responseData1=JSON.parse(authCtx.antworte);
  arr=responseData1;
  //console.log(arr, arr[0].fkAuftragWartungsChecklistenFrageID, row.fkAuftragWartungsChecklistenFrageID, arr[0].lfdAuftragWartungsAusfuehrungFrageID);
  let filtered=arr.filter(el=>el.fkAuftragWartungsChecklistenFrageID===row.fkAuftragWartungsChecklistenFrageID)
  // console.log(filtered);
  if (filtered.length>0){
    for (let z=0; z < arr.length; z++){
     
      if (arr[z].fkAuftragWartungsChecklistenFrageID===row.fkAuftragWartungsChecklistenFrageID){
        let updatedRow=arr[z];
        updatedRow=row;
        
        arr[z]=updatedRow;
     //   console.log(updatedRow,row);
     //   console.log(filtered)
      }
         }
  
  } else {
   
    arr.push(row);
   
  }
  authCtx.addAntworte(JSON.stringify(arr))
  }
  
  }

    



    return (

        <Fragment>

<div  className='formblock' >
				 
				 <div className='formblock_top' >
         <div className='formblock_left' >
                <div className='formblock_nr_data' >
             
                     <div className='formblock_data' > <p> {props.pos}. &nbsp; {props.name}  </p></div>
                     
                </div>
            <div className='formblock_right' >
                <div className='formblock_name'  style={{display: props.wert1==='0' || props.wert1===null ? 'none' : 'flex'}}>
                      <label htmlFor='Name'>{label1}:</label>
                      <input className='Name'
                          value={wert1Value}
                          required
                          type="text"  
                          ref={wert1InputRef} 
                          onChange={event=>setwert1Value(event.target.value)}
                          />
                </div>
                <div className='formblock_name' style={{display: props.wert2==='0' || props.wert2===null ? 'none' : 'flex'}}>
                      <label htmlFor='Name'>{label2}:</label>
                      <input className='Name'
                        value={wert2Value}
                        required
                        type="text"  
                        ref={wert2InputRef} 
                        onChange={event=>setwert2Value(event.target.value)}/>
                </div>
                <div className='formblock_name' style={{display: props.wert3==='0' || props.wert3===null ? 'none' : 'flex'}}>
                      <label htmlFor='Name'>{label3}:</label>
                      <input className='Name' 
                          value={wert3Value}
                          required
                          type="text" 
                          ref={wert3InputRef} 
                          onChange={event=>setwert3Value(event.target.value)}/>
                </div>
               
                </div>
               </div>
               <div className='formblock_buttons' >
               <div onClick={clickEditHandler} style={{ color: (props.massnahmen!==null && props.massnahmen!=='')   ? 'red' : 'black'}}> <BiMessageRoundedError/></div>
                            <div className='box-1' style={{ opacity:props.geprueft==='0'  ? '1' : '0.4'}}  onClick={clickNoHandler2}>
                               <img src={require('../images/wichtig.svg').default} alt='close' />
                            </div>
                            <div className='box' style={{ opacity:props.geprueft==='1'  ? '1' : '0.4'}}  onClick={clickYesHandler}>
                                <img src={require('../images/hacken.svg').default} alt='abschliessen' />
                            </div>
                    </div>
                    
                    </div>
				 
				 </div>
        </Fragment>
      );

}
export default FrageForm2;