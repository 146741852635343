


const PumpeItem=(props)=>{
  
    //console.log(Number(props.saved))
    let description;
    if (props.description){ 
     description=`(${props.description})`;
    } else {
        description=`()`;
    }
    //const path=props.path;
    return (

                   <div className='linkliste' onClick={props.onClick}> 
           
           <div className='linktext'>
                    Pumpe  {props.nr} {props.name} {description}
          
                    </div>
            <div className='linkbutton'>
            <img src={require('../images/weiter.svg').default} alt='weiter' />
            </div>
            </div>   
   );
};

export default PumpeItem;