import { Link } from 'react-router-dom'
import Header from '../component/Header';
import React, { useState } from 'react';
import FragenItem from '../component/FragenItem';
import { useEffect, useContext } from 'react';
//import ChecklistContext from '../store/checklist-context';
import AuthContext from '../store/auth-context';
import { useNavigate } from "react-router-dom";
import NodataMessage from '../component/NodataMessage';
function Fragen() {
  const authCtx = useContext(AuthContext);
  const navigate = useNavigate();
  let pageTitle='Wartung: Bereiche';
  const [fragenList, setFragenList]=useState();
  const [isLoading, setIsLoading]=useState(true);
  const [httpError, setHttpError]=useState();
  const [selectedBereich, setSelectedBereich]=useState();

  const [pageName, setPageName] = useState( 
    {name: pageTitle, 
    link1: '/bereiche', 
    link2: '/'});
   // const checklistCtx=useContext(ChecklistContext);
   let antwData1=[];
   if (authCtx.antworte!==null){
    antwData1=JSON.parse(authCtx.antworte);
    
  
  } 
  const [array, setArray]=useState([]);
  const [antworteList, setAntworteList]=useState(antwData1);
 

  
 function editBereichlist(){
 // console.log('editBereichlist-fragen')
 // console.log(authCtx.antworte);
 // console.log(antworteList);
  let bereichlist=authCtx.bereichlist;
 let bereichlistData=JSON.parse(bereichlist);
 //console.log(bereichlistData)
 let fragenlist=authCtx.fragenlist;
 let fragenlistData=JSON.parse(fragenlist);
let updatedBereich=bereichlistData.filter(el=>Number(el.lfdAuftragWartungsBereichID)===Number(authCtx.bereichid))
let filteredfragenlistData=fragenlistData.filter(el=>Number(el.fkAuftragWartungsBereichID)===Number(authCtx.bereichid));
//console.log(authCtx.bereichid, updatedBereich, fragenlistData)
let countfr=filteredfragenlistData.length;
let countgepr=0;
if(filteredfragenlistData.length>0){
   
  for (const k in filteredfragenlistData){
    
    let frNumstr=filteredfragenlistData[k].lfdAuftragWartungsChecklistenFrageID;
    let frNumstr1=frNumstr.toString();
    let filteredAntworte=antworteList.filter(el=>el.fkAuftragWartungsChecklistenFrageID===frNumstr1)
   // console.log(filteredAntworte);
    if (filteredAntworte){
      if (filteredAntworte.length>0){
        countgepr++;
      
      }
    }
 // countfr++;
  }

  let Num1=countfr.toString();
  let Num2=countgepr.toString();
  if(Num1===Num2) {
   // let updatedRow=bereichlistData.filter(el=>el.lfdAuftragWartungsBereichID===authCtx.bereichid);
    //let updatedRowIndex=bereichlistData.findIndex(el=>el.lfdAuftragWartungsBereichID===authCtx.bereichid);
  // console.log(updatedBereich, Num1, Num2, authCtx.bereichid)
   // console.log(bereichlistData[updatedRowIndex])
   if (updatedBereich){
    updatedBereich[0].countFr=Num1;
    updatedBereich[0].CountGeprueft=Num2;
    // console.log(JSON.stringify(bereichlistData))
     authCtx.addBereichlist(JSON.stringify(bereichlistData));
   //*  console.log(80)
   }
  }

  //bereichlistData.filter(el=>el.lfdAuftragWartungsBereichID===authCtx.bereichid);

 
 
  
 
   
  //console.log(updatedRow)
    
     //console.log(bereichlistData)
    
    
   


}

}

 



 //let updatedRowIndex=bereichlistData.findIndex(el=>el.lfdAuftragWartungsBereichID===authCtx.bereichid);
// console.log(updatedRow)
// console.log(bereichlistData[updatedRowIndex])
/*
if (updatedRow){
  updatedRow[0].countFr=Num1;
  updatedRow[0].CountGeprueft=Num2;
}
 
//console.log(updatedRow)
  for (const z in bereichlistData){
    if ( bereichlistData[z].lfdAuftragWartungsBereichID===authCtx.bereichid){
     // console.log(bereichlistData[z])
    }
   //console.log(bereichlistData)
   authCtx.addBereichlist(JSON.stringify(bereichlistData));
  }
 */



   
    useEffect(()=> {

      /*----------------
      const fetchItems=async()=>{
            setIsLoading(true);
            let fkAuftragWartungsChecklisteID=authCtx.checklistid;
            let url='https://tbsmobile.de/react-bereiche.php?id='+fkAuftragWartungsChecklisteID;
          const response=await fetch(url, {
      // 15 is User Identifier
      
            headers: {
              'Content-Type': 'application/json'
              // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            // redirect: 'follow', // manual, *follow, error
            // referrerPolicy: 'no-referrer',
        });
    

    if(!response.ok){
      throw new Error('Something went wrong!');
    }
    const responseData=await response.json(); //
     */
    
//transform to array


if ( authCtx.antworte!==null){
  antwData1=JSON.parse(authCtx.antworte);
  setAntworteList(antwData1);
 // editBereichlist();
}
//editBereichlist();
makeList()
 //editBereichlist();
      
},[authCtx.bereichlist]);


function makeList(){
  const responseData=JSON.parse(authCtx.bereichlist);

  const loadedItems=[];

  for (const key in responseData){
  //console.log(responseData[key]);
        loadedItems.push({
          id: responseData[key].lfdAuftragWartungsBereichID,
          name: responseData[key].ChecklistenBereich,
          description: responseData[key].Wert1,
          pos: responseData[key].PosNr,
          wert2:  responseData[key].Wert2,
          wert3:  responseData[key].Wert3,
          wert4:  responseData[key].Wert4,
          countFr: responseData[key].countFr,
          CountGeprueft: responseData[key].CountGeprueft,
    });
  }
  setFragenList(loadedItems);
  setIsLoading(false);

// };
//endfetch

/*
fetchItems().catch(error=>{
      setIsLoading(false);
      setHttpError(error.message);
     });
*/

   //  console.log('Fragen1')
    editBereichlist()
}


const clickItemHandler=(frage)=>{ 
 // console.log(frage);
  //item.id -->checklistid
  authCtx.addBereichid(frage.id);
 navigate('/fragennew')
 
}

const clickWeiterHandler=()=>{
  navigate('/bereiche')
 }

if (isLoading){
  return (
    <div> 
    <Header items={pageName}/>
    <section >
      <NodataMessage>Loading...</NodataMessage>
    </section>
    </div>
  );
}

if (httpError) {
  return (
    <div> 
        <Header items={pageName}/>
          <div className="wrapper">
          <section >
          <NodataMessage>{httpError}</NodataMessage>
          </section>
          </div>
    </div>
    );
    }

  if (fragenList.length===0) {
    return (
      <div>
          <Header items={pageName}/>
          <div className="wrapper">
            <section >
            <NodataMessage>Keine Datei</NodataMessage>
            </section>
          </div>
      </div>
      );
      }
      
  return (
    <div>
        <Header items={pageName}/>
        <div className="wrapper">
            <section>
              <div >
   
          {
            fragenList.map((frage)=> (
                    <FragenItem  onClick={clickItemHandler.bind(null, frage)}
                          key={frage.id}
                          id={frage.id}
                          name={frage.name}
                          description={frage.description}
                          pos={frage.pos}
                          wert2={frage.wert2}
                          wert3={frage.wert3}
                          wert4={frage.wert4}
                          countFr={frage.countFr}
                          countGeprueft={frage.CountGeprueft}
                          
                      />))
          }
    
         </div>
        </section>
    
        <div className="footer-wrapper">
            <div className="buttons">
                  <button className="bnt-right" onClick={clickWeiterHandler} ><p>Weiter</p><img src={require('../images/weiter.svg').default} alt='aweiter' /></button>
            </div>
          </div>
    </div>
    </div>
  )
}

export default Fragen
