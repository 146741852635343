

import { Fragment } from "react";
import React, { useState, useEffect, useContext  } from 'react';
import { useNavigate } from 'react-router-dom';

import AuthContext from '../store/auth-context';
import { GrHome } from "react-icons/gr";
import { FaStepBackward } from "react-icons/fa";
import { FaFileSignature } from "react-icons/fa";
import { GrCaretNext } from "react-icons/gr";

import { Link } from 'react-router-dom';





const Header= (props) =>{
        const authCtx = useContext(AuthContext);
        const navigate = useNavigate();
        const pagetitle=props.items.name;
        const [homeBtnName, sethomeBtnName]=useState('');
        const [backBtn, setBackBtn] = useState({name:'Zurück', path: props.items.link1, hide: props.items.hide1});
        const [homeBtn, setHomeBtn] = useState({name: '', path: props.items.link2, hide: props.items.hide2});
useEffect(()=>{
       if (pagetitle==='Liste Wartungsaufträge') {
        sethomeBtnName('Abmelden')

       } else if (pagetitle==='Unterschrift Kunde ' || pagetitle==='Melderliste' || pagetitle==='Unterschrift Monteur ' ) {
        sethomeBtnName('Weiter')
       } else {
        sethomeBtnName('Home')
       }
       setBackBtn({name:'Zurück', path: props.items.link1, hide: props.items.hide1});
}, [pagetitle])

   



//
//onClick={abmelden}

const homeButtonClick=()=>{
if (homeBtnName==='Abmelden'){
        authCtx.delAuftragid();
        authCtx.logout();
 } else if (pagetitle==='Unterschrift Kunde ' ||  pagetitle==='Unterschrift Monteur ' ) {
    
        props.onClick();

 } else if (pagetitle==='Melderliste' ) {
        navigate('/unterschrift')
        
} else {
        navigate(homeBtn.path)
}
}

const backButtonClick=()=>{
        console.log(pagetitle==='Unterschrift Monteur ')
        if (pagetitle==='Unterschrift Monteur ') {
                        props.onBack()
                       // console.log(pagetitle)
        } else {
                navigate(backBtn.path)
        }        
        
}


    return (
        <Fragment>
            <header>

                    

                  

                
                        



                <div className="left" >
                
                         <button style={{display: backBtn.hide==='yes' ? 'none' : 'flex'}} onClick={backButtonClick}>
                                <img src={require('../images/back.svg').default} alt='zurück' />
                                <p>{backBtn.name} </p>
                        </button>
                         

                
                </div>

                <div className="center">
                                {pagetitle}  
                                {pagetitle==='Unterschrift Kunde ' && (  <FaFileSignature/>)}
                                {pagetitle==='Unterschrift Monteur ' && (  <FaFileSignature/>)}
                </div>

                <div className="right" >
                        
                         <button  style={{display: homeBtn.hide==='yes' ? 'none' : 'flex'}}
                          onClick={homeButtonClick}>
                                        <p>{homeBtnName} </p> 
                                        {homeBtnName==='Home' && (
                                                        <img src={require('../images/home.svg').default}alt='home' />
                                        )}
                                        {homeBtnName==='Abmelden' && (
                                                <img src={require('../images/abmelden.svg').default} alt='abmelden' />
                                        )}
                                          {homeBtnName==='Weiter' && (<GrCaretNext/>)} 
                         </button>
                        
                        
                </div>
                    

            </header>
            
        </Fragment>
    );
}

export default Header;