//import { Link } from 'react-router-dom'
import Header from '../component/Header';
import React, { useState } from 'react';

import { useEffect, useContext } from 'react';
//import ChecklistContext from '../store/checklist-context';
import AuthContext from '../store/auth-context';
import BereichDetail from '../component/BereichDetail';
import Right from '../images/right.png';
import Left from '../images/left.png';
import FrageForm from '../component/FrageForm'



function FrageDetail() {
  const pageName=
    {name: 'Fragen', 
    link1: '/fragen', 
    link2: '/'};
   
  const fragenList=[];
  const checklistList=[];
  const [fragenfilteredList, setFragenfilteredList]=useState([]);
  const [frageNum, setFrageNum]=useState(1);
  const authCtx = useContext(AuthContext);
  const bereichid=authCtx.bereichid;
  const bereichlist=authCtx.bereichlist;
  let responseData1=JSON.parse(bereichlist);
      responseData1=responseData1.filter(el=> el.lfdAuftragWartungsBereichID===bereichid);
      //const loadedItems1=[];
  let responseDatafrage1=JSON.parse(authCtx.fragenlist);
  let responseDatafrage2=responseDatafrage1.filter(el=>el.fkAuftragWartungsChecklisteID===authCtx.checklistid && el.fkAuftragWartungsBereichID===bereichid);
  for (const key in responseDatafrage2){
    
        checklistList.push({
          id: responseDatafrage2[key].lfdAuftragWartungsChecklistenFrageID,
          name: responseDatafrage2[key].ChecklistenFrage,
          pos: responseDatafrage2[key].PosNr,
          
      });
      }
 
 const hasItems=checklistList.length>0;

if (bereichlist!==null) {
      for (const key in responseData1){
      
        fragenList.push({
          id: responseData1[key].lfdAuftragWartungsBereichID,
          name: responseData1[key].ChecklistenBereich,
          description: responseData1[key].Wert1,
          pos: responseData1[key].PosNr,
          wert2:  responseData1[key].Wert2,
          wert3:  responseData1[key].Wert3,
          wert4:  responseData1[key].Wert4,
          wert5:  responseData1[key].Wert5,
          wert6:  responseData1[key].Wert6,
          wert1exist: responseData1[key].Wert1Exist,
          wert2exist: responseData1[key].Wert2Exist,
          wert3exist: responseData1[key].Wert3Exist,
          wert4exist: responseData1[key].Wert4Exist,
          wert5exist: responseData1[key].Wert5Exist,
          wert6exist: responseData1[key].Wert6Exist,
          WertBeschriftung1: responseData1[key].WertBeschriftung1,
          WertBeschriftung2: responseData1[key].WertBeschriftung2,
          WertBeschriftung3: responseData1[key].WertBeschriftung3,
          WertBeschriftung4: responseData1[key].WertBeschriftung4,
          WertBeschriftung5: responseData1[key].WertBeschriftung5,
          WertBeschriftung6: responseData1[key].WertBeschriftung6,
    });
  }
}

console.log(fragenList, 'fragenList')


let filteredFrage
useEffect(()=> {

//checklistList
//loadedItemsfrage1
filteredFrage=checklistList.filter(el=>el.pos===frageNum.toString())

    //setIsLoading(false);
    setFragenfilteredList(filteredFrage);

  }, [])


  if (fragenList.length===0) {
    return (
      <div>
      <Header items={pageName}/>
    <section >
    <p>Keine Datei</p>
  </section>
  </div>
  );
  }

  const frageChecklistContent= (
    <React.Fragment>
    { hasItems && (
        
        fragenfilteredList.map((frage)=> (
            <FrageForm 
                key={frage.id}
                id={frage.id}
                name={frage.name}
                pos={frage.pos}

          />))

    )}
  { !hasItems && ( 
    
        <p>
          Keine Fragen
        </p>)}
  
    


</React.Fragment>);


        
    return (
      <div>
          <Header items={pageName}/>
          <div className="wrapper">
          <section className='liste'>
          {
              fragenList.map((frage)=> (
                      <BereichDetail 
                            key={frage.id}
                            id={frage.id}
                            name={frage.name}
                            description={frage.description}
                            pos={frage.pos}
                            wert2={frage.wert2}
                            wert3={frage.wert3}
                            wert4={frage.wert4}
                            wert5={frage.wert5}
                            wert6={frage.wert6}
                            wert1exist={frage.wert1exist}
                            wert2exist={frage.wert2exist}
                            wert3exist={frage.wert3exist}
                            wert4exist={frage.wert4exist}
                            wert5exist={frage.wert5exist}
                            wert6exist={frage.wert6exist}
                            WertBeschriftung1={frage.WertBeschriftung1}
                            WertBeschriftung2={frage.WertBeschriftung2}
                            WertBeschriftung3={frage.WertBeschriftung3}
                            WertBeschriftung4={frage.WertBeschriftung4}
                            WertBeschriftung5={frage.WertBeschriftung5}
                            WertBeschriftung6={frage.WertBeschriftung6}
                        />))
            }
        
          </section>
      
         
      <section>
{frageChecklistContent}
           

          </section>
       
      </div>
      </div>
    )
  }
  
  export default FrageDetail
  