import { Link } from 'react-router-dom'
import Header from '../component/Header'
import React, { useState } from 'react';
import WartungItem from '../component/WartungItem';
import BereicheItem from '../component/BereicheItem';
import { useEffect } from 'react';
//import '../pages/Wartungen.css'
const BEREICHELIST=[
{id: 'b3',
name: 'Abschlissen',
path: '/'

},
{id: 'b4',
name: 'Abbrechen',
path: '/'
},
];

function Wartungen() {
  
  const[wartungenList, setWartungenList]=useState();
  const [pageName, setPageName] = useState( 
    {name: 'Liste Wartungen', 
    link1: '/auftraege', 
    link2: '/'});

  

  const [isLoading, setIsLoading]=useState(true);
  const [httpError, setHttpError]=useState();
  useEffect(()=> {
      const fetchItems=async()=>{
            setIsLoading(true);
          const response=await fetch('https://tbsapp.de/react-index.php?15', {
      // 15 is User Identifier
      
            headers: {
              'Content-Type': 'application/json'
              // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            // redirect: 'follow', // manual, *follow, error
            // referrerPolicy: 'no-referrer',
        });
   
    if(!response.ok){
      throw new Error('Something went wrong!');
    }
    const responseData=await response.json();
//transform to array
    const loadedItems=[];

    for (const key in responseData){
    
          loadedItems.push({
            id: responseData[key].lfdAuftragWartungsAusfuehrungID,
            name: responseData[key].ChecklistenTyp,
            description: responseData[key].AuftragsNr,
            bz: responseData[key].BauvorhabenZ1,
            date:responseData[key].Datum,
            bemerkung: responseData[key].Bemerkung,
            user: responseData[key].Monteur
      });
    }
    setWartungenList(loadedItems);
    setIsLoading(false);

  };


  fetchItems().catch(error=>{
        setIsLoading(false);
        setHttpError(error.message);
       });
    
 
  
  
}, []);

const clickItemHandler=(wartung)=>{ 
  console.log(wartung);
}

if (isLoading){
  return (
    <section >
      <p>Loading...</p>
    </section>
  );
}
  if (httpError) {
    return (
    <section >
    <p>{httpError}</p>
  </section>
  );
  }
    
 



  return (
    <div>
      
      <Header items={pageName}/>
      <div className="wrapper">
      <section >
        <div >  
    
      {
                  wartungenList.map((wartung)=> (
                   
                  <WartungItem  onClick={clickItemHandler.bind(null, wartung)} 
                          key={wartung.id}
                          id={wartung.id}
                          name={wartung.name}
                         description={wartung.description}
                          bz={wartung.bz}
                        date={wartung.date}
                        bemerkung={wartung.bemerkung}
                      user={wartung.user}
     
                  />
                 
                  ))
                }
     
      </div>
      <div>
      
         {
            BEREICHELIST.map((item)=> (
                    <BereicheItem 
                          key={item.id}
                          id={item.id}
                          name={item.name}
                          path={item.path}
        
                      />))
          }
       
       </div>
</section>
</div>
    </div>
  )
}

export default Wartungen
