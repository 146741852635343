



const BereicheItem=(props)=>{


    const path=props.path;

    return (<div className="bereiche-click"  onClick={props.onClick} >
            
                     <button >
                        { props.name==='Bereiche' && (
                            <img src={require('../images/bereiche.svg').default} alt='bereiche' />
                        )}
                         { props.name==='Pumpen' && (
                            <img src={require('../images/pumpen.svg').default} alt='apumpen' />
                        )}
                        
                    <p> {props.name}</p>
                     </button>  
            
    </div>);
};

export default BereicheItem;