import { Link } from 'react-router-dom'
import Header from '../component/Header'
import React, { useState } from 'react';
import BereicheItem from '../component/BereicheItem';
import { useNavigate } from "react-router-dom";
import Message from '../component/Message/Message';
import AuthContext from '../store/auth-context';
import { useEffect, useContext } from 'react';
import { GrClose } from "react-icons/gr";
import { GrCheckmark } from "react-icons/gr";

function Bereiche(props) {
  const navigate = useNavigate();
  const authCtx = useContext(AuthContext);


  const userid=authCtx.userid;
  const awa=authCtx.ausfuehrungid;
  const awc=authCtx.checklistid;
  
  const [isLoading, setIsLoading]=useState(true);
  const [httpError, setHttpError]=useState();
  const [message, setMessage]=useState();
  
  const [pageName, setPageName] = useState( 
    {name: 'Wartung: Bereiche', 
    link1: '/checklist', 
    link2: '/'});

    const BEREICHELIST=[
      {id: 'b1',
       name: 'Bereiche',
       path: '/Fragen'
    },
    {id: 'b2',
    name: 'Pumpen',
    path: '/Pumpen'
    },
    ];

  const clickItemHandler=(item)=>{
   let path=item.path;

   if (path!=='/') {
    navigate(path)
   } else {
   
 
   
   }

  };
 
 useEffect(()=>{
//console.log('enterbereich')
//send url#
//console.log(status)

 
  updatestatus(0)

   
 },[])


 
	   
function updatestatus(del){

  let url='https://tbsmobile.de/react-updatestatus.php?userid='+userid+'&awa='+awa+'&awc='+awc+'&del='+del;
  console.log(url)
  const fetchItems=async()=>{
    setIsLoading(true);
    const response=await fetch(url, {
  // 15 is User Identifier
  
    headers: {
      'Content-Type': 'application/json'
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    // redirect: 'follow', // manual, *follow, error
    // referrerPolicy: 'no-referrer',
  });
  
  // console.log(response)
  if(!response.ok){
  throw new Error('Something went wrong!');
  }
  const responseData=await response.json();
  //transform to array
  //const loadedItems=[];
  console.log(responseData)
 
  setIsLoading(false);
  
  };
  
  
  fetchItems().catch(error=>{
  setIsLoading(false);
  setHttpError(error.message);
  });
  

}


const actionHandler=()=>{
  
  if (message==='Wartung abschließen?'){
   
    setMessage(null);
    navigate('/bereiche-datei');
  }
  if (message==='Abbrechen? (Änderungen werden gelöscht.)'){
   
    setMessage(null);
    updateantworte();
    updatepumpe();
    //abbrechen

    
  }

 // 
  //'/bereiche'
  
}


const clearMessage=()=>{
 
  setMessage(null);
  
 
}

const clickAbschiessenHandler=(event)=>{
  event.preventDefault();
  
      //check if all checklists are fillted
      const responseData=JSON.parse(authCtx.bereichlist);
      const loadedItems=[];
    
      for (const key in responseData){
      //console.log(responseData[key]);
            loadedItems.push({
              id: responseData[key].lfdAuftragWartungsBereichID,
              name: responseData[key].ChecklistenBereich,
              description: responseData[key].Wert1,
              pos: responseData[key].PosNr,
              wert2:  responseData[key].Wert2,
              wert3:  responseData[key].Wert3,
              wert4:  responseData[key].Wert4,
              countFr: responseData[key].countFr,
              CountGeprueft: responseData[key].CountGeprueft,
        });
      }

     
      
      let num=loadedItems.filter(el=>el.countFr!==el.CountGeprueft).length;
      let totalnum=loadedItems.length;

      const responsepumpeData=JSON.parse(authCtx.pumpelist);
      const loadedpumpeItems=[];

      for (const key in responsepumpeData){
        //console.log(responseData[key]);
        loadedpumpeItems.push({
                id: responsepumpeData[key].lfdAuftragWartungsPumpeID,
                saved: responsepumpeData[key].saved,
          });
        }
  
        let numpumpe=loadedpumpeItems.filter(el=>Number(el.saved)===1).length;
        let totalnumpumpe=loadedpumpeItems.length;
     
        if (num>0) {
          //  alert(num+' von '+totalnum +' Bereichen nicht abgeschlossen')
          alert('Sie müssen all Fragen beantworten')
          return;
          } else {
            if (totalnumpumpe-numpumpe>0){
              let pumpentocheck=totalnumpumpe-numpumpe;
              console.log(totalnumpumpe,numpumpe, pumpentocheck)
              alert(pumpentocheck+' Pumpen nicht abgeschlossen')
             
              return;
            } else {

                setMessage('Wartung abschließen?')
              //navigate('/bereiche-datei')
              /*
              props.onAbschliessen();
              updatestatus(1)
              console.log('Abschliessen')
              navigate('/checklist')
              */
            }



          
          }

}

const clickAbbrechnenHandler=(event)=>{
  event.preventDefault();
  setMessage('Abbrechen? (Änderungen werden gelöscht.)');

}


async function updatepumpe(){


  const urlpumpe='https://tbsmobile.de/react-checklistepumpe.php?id='+authCtx.checklistid+'&ausfuehrung='+authCtx.ausfuehrungid;
  
const fetchPumpeItems=async()=>{
  setIsLoading(true);
  const responsePumpe=await fetch(urlpumpe, {

    headers: {
      'Content-Type': 'application/json'
    },
   
});

if(!responsePumpe.ok){
  throw new Error('Something went wrong!');
}
const responsePumpeData=await responsePumpe.json();
//transform to array
//console.log(responsePumpeData, 'responsePumpeData')
const loadedPumpeItems=[];
for (const key in responsePumpeData){
  loadedPumpeItems.push({
    lfdAuftragWartungsPumpeID: responsePumpeData[key].lfdAuftragWartungsPumpeID,
    fkAuftragWartungsChecklisteID: responsePumpeData[key].fkAuftragWartungsChecklisteID,
    Protokolltyp: responsePumpeData[key].Protokolltyp,
    Nr: responsePumpeData[key].Nr,
    Typ:  responsePumpeData[key].Typ,
    Fabrikat:  responsePumpeData[key].Fabrikat,
   
});

}

authCtx.addPumpelist(JSON.stringify(responsePumpeData));

}

fetchPumpeItems().catch(error=>{
  //setIsLoading(false);
  //setHttpError(error.message);
  console.log(error.message);
  });

}
  
async function updateantworte(){

  let ausfuehrungid=authCtx.ausfuehrungid;
  let urlfrageitems='https://tbsmobile.de/react-fragenlist.php?id='+ausfuehrungid;
  const fetchFrageItems=async()=>{
    setIsLoading(true);
    const responseFrageItem=await fetch(urlfrageitems, {
  
      headers: {
        'Content-Type': 'application/json'
      },
     
  });
  
  if(!responseFrageItem.ok){
    throw new Error('Something went wrong!');
  }
  const responseFrageItemData=await responseFrageItem.json();
  //transform to array
  
  const loadedFrageItems=[];
  if (responseFrageItemData.length>0) {
   
      for (const key in responseFrageItemData){
          loadedFrageItems.push({
            lfdAuftragWartungsAusfuehrungFrageID: responseFrageItemData[key].lfdAuftragWartungsAusfuehrungFrageID,
            fkAuftragWartungsAusfuehrungID: responseFrageItemData[key].fkAuftragWartungsAusfuehrungID,
            fkAuftragWartungsChecklistenFrageID: responseFrageItemData[key].fkAuftragWartungsChecklistenFrageID,
            Geprueft: responseFrageItemData[key].Geprueft,
            Wert1:  responseFrageItemData[key].Wert1,
            Wert2:  responseFrageItemData[key].Wert2,
            Wert3:  responseFrageItemData[key].Wert3,
            Massnahmen:  responseFrageItemData[key].Massnahmen,
        });
  
      }
      authCtx.addAntworte(JSON.stringify(responseFrageItemData)); 
} else {
  authCtx.addAntworte(null);
}

  
  }
  
  fetchFrageItems().catch(error=>{
    //setIsLoading(false);
    //setHttpError(error.message);
    console.log(error.message);
    });

}


  return (
   
    <div>
        {message && <Message onClose={clearMessage} onAction={actionHandler}>{message}</Message>}
      <Header items={pageName}/>
      <div className="wrapper">
      <section> 
       <div>
         {
            BEREICHELIST.map((item)=> (
                    <BereicheItem onClick={clickItemHandler.bind(null, item)}
                          key={item.id}
                          id={item.id}
                          name={item.name}
                          path={item.path}
        
                      />))
          }
        
       </div>

       </section>
      

     
       <div>

            <div className="footer-wrapper">
            <div className="buttons">
              <button  className="bnt-left"  onClick={clickAbbrechnenHandler}><p>Abbrechnen</p><img src={require('../images/close.svg').default} alt='close' /></button>
            
              <button className="bnt-right  --clr-green-dark" onClick={clickAbschiessenHandler}><p>Abschiessen</p><img src={require('../images/hacken.svg').default} alt='abschliessen' /></button>
            </div>
            </div>

       



     
       </div>
       </div>
    </div>
  )
}

export default Bereiche
