import { Link } from 'react-router-dom'

function Error() {
  return (
    <div>
      <h2>404</h2>
      <p>Es ist ein Fehler aufgetreten. Seite nicht gefunden!</p>
      <Link to='/' className='btn'>
        Back to Home
      </Link>
    </div>
  )
}

export default Error
