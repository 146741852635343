import { BrowserRouter, Routes, Route, Router, useLinkClickHandler, Switch, Redirect, Navigate   } from 'react-router-dom'
import React, { useEffect, useState} from "react";
import Login from './pages/Login'
import Auftraege from './pages/Auftraege'
import Wartungen from './pages/Wartungen'
import Checklist from './pages/Checklist'
import Bereiche from './pages/Bereiche'
import Fragen from './pages/Fragen'
import FragenNew from './pages/FragenNew'
import Pumpen from './pages/Pumpen'
import FrageDetail from './pages/FrageDetail'
import PumpeDetail from './pages/PumpeDetail'
import Error from './pages/Error'
import AuthContext from './store/auth-context';
import AbschliessenBereiche from './pages/AbschiessenBereiche';
import Abschliessen from './component/Abschliessen'
import Melderliste from './pages/Melderliste';
import Unterschrift from './pages/Unterschrift';
import { AuthContextProvider } from './store/auth-context';
import { useNavigate, useHistory } from "react-router-dom";
import { useContext } from 'react';
import Layout from './component/Layout'
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom'

import { NavLink } from 'react-router-dom';




function App() {
 
 // const [modalAbbrechenIsShown, setModalAbbrechenIsShown]=useState(false);
  const [modalIsShown, setModalIsShown]=useState(false);
  const [modalPumpeIsShown, setModalPumpeIsShown]=useState(false);
  const [modalChecklistStartIsShown, setModalChecklistStartIsShown]=useState(false);
  const [buttonText, setButtonText]=useState();

  const showModalHandler=(event)=>{
   
  
    let buttonText=event.target.innerText;
    setButtonText(buttonText)
    if (buttonText==='Abschlissen') {
        
    } else if (buttonText==='Bereiche'){
        console.log(buttonText)
    }
     
  };

  


 










  const hideModalHandler=()=>{
    if(modalIsShown===true) {
      setModalIsShown(false);
    }
    if (modalChecklistStartIsShown===true){
      setModalChecklistStartIsShown(false);
     
    }
  
    if (modalPumpeIsShown===true){
      setModalPumpeIsShown(false)
    }
  };

  const saveModalHandler=()=>{
    
    setModalIsShown(false);
  };



 

  
  const showAbschliessenHandler=()=>{
    setModalIsShown(true);
    console.log('showAbschliessenHandler')
  };

 
 
  return (
    <AuthContextProvider>
   {modalIsShown && <Abschliessen onClose={hideModalHandler} onSave={saveModalHandler}/>}

  
  
  
 

     <BrowserRouter > 
   
    
    
 <Routes>
   
 <Route path='/' element={<Login />} />


 

<Route path='auftraege' element={<Auftraege />} />

<Route path='wartungen' element={<Wartungen />} />
<Route path='checklist' element={<Checklist  />} />

<Route path='bereiche' element={<Bereiche  onAbschliessen={showAbschliessenHandler} />} />
<Route path='bereiche-datei' element={<AbschliessenBereiche  />} />
<Route path='melderliste' element={<Melderliste  />} />
<Route path='unterschrift' element={<Unterschrift  />} />
<Route path='fragen' element={<Fragen />} />
<Route path='fragennew' element={<FragenNew />} />
<Route path='fragedetail' element={<FrageDetail />} />
<Route path='pumpen' element={<Pumpen />} />
<Route path='pumpedetail' element={<PumpeDetail    />} />
<Route path='*' element={<Error />} />

</Routes>       
         </BrowserRouter>
      
         </AuthContextProvider>
  )
}

export default App
