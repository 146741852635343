import Yes from '../images/yes.png';
import No from '../images/no.png';
//import '../component/FrageForm.css'
import { useRef, useState } from 'react';

import { Fragment } from "react";

const FrageForm = (props) => {
  const textInputRef=useRef();

    const clickYesHandler=()=>{
        console.log('yes');
       let newRow={};
        newRow.fkAuftragWartungsAusfuehrungID=1;
        newRow.fkAuftragWartungsChecklistenFrageID=props.id;
        newRow.Geprueft=1;
        newRow.Massnahmen=textInputRef.current.value;
        console.log(newRow);
        //tblauftragwartungsausfuehrungfrage`
        
//lfdAuftragWartungsAusfuehrungFrageID	
//fkAuftragWartungsAusfuehrungID	
//fkAuftragWartungsChecklistenFrageID	
//Geprueft	
//Wert1	
//Wert2	
//Wert3	
//Massnahmen
    }

    const clickNoHandler=()=>{
      let bemerkung=textInputRef.current.value;
      if (bemerkung.length>0){
        console.log('no');
        let newRow={};
        newRow.fkAuftragWartungsAusfuehrungID=1;
        newRow.fkAuftragWartungsChecklistenFrageID=1;
        newRow.Geprueft=0;
        newRow.Massnahmen=bemerkung;
        console.log(newRow);
      } else {
        alert('Enter text')
      }
   
    }

    return (

        <Fragment>
          <h2>Frage {props.pos} {props.name}</h2>
          <div className='Form--log'>
                <label htmlFor='bar'>bar:</label>
                <input  type="Name"id="bar" name="bar"/>
                <label htmlFor='sek'>sek:</label>
                <input  className='Name' type="Name"  id="sek" name="sek"/>
          <div className='box'  onClick={clickYesHandler}>
            <i><img src={Yes} alt=""/></i>
          </div>
          <div className='box--1' onClick={clickNoHandler}>
            <i><img src={No} alt=""/></i>
          </div>
              <p>Massnahme/Bemerkung:</p>
              <input ref={textInputRef} className='Abschliessen' id="Abschliessen" name="Abschliessen"/>
              <hr className='h'></hr>
        </div>
       
        </Fragment>
      );

}
export default FrageForm;