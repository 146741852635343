import { Fragment } from "react";
import AuthContext from '../store/auth-context';
import {  useContext } from 'react';

import { useRef, useState, useEffect  } from 'react';

const PumpeForm = (props) => {
// const authCtx = useContext(AuthContext);
let pumpetype;
if (props){
  pumpetype=Number(props.pumpetype);
}
   

 
 //let pumpefiltered=pumpelist.filter(el=>el.lfdAuftragWartungsPumpeID===pumpeid);
 
  const [druckManDrWert, setDruckManDrWert]=useState(props.druckd);
  const [druckManSaWert, setDruckManSaWert]=useState(props.drucks);
  const [druckPumpeVorhWert, setDruckPumpeVorhWert]=useState();
  const [anfangsdruckWert, setAnfangsdruckWert]=useState();
  
  const [druckudWert, setDruckudWert]=useState(props.druckud);
  const [drehWert, setDrehWert]=useState(props.drehd);
  const [stromeWert, setStromeWert]=useState(props.strome);
  const [stromnWert, setStromnWert]=useState(props.stromn);
  const [stromnnWert, setStromnnWert]=useState(props.stromnn);
  let druckManDrRef=useRef();
  let druckManSaRef=useRef();
  //const druckPumvorhRef=useRef();
  let stromaufnahmRef=useRef();
  let stromaufnahmnRef=useRef();
  let stromaufnahmnnRef=useRef();
  let drehRef=useRef();
  let druckudRef=useRef();

  useEffect(()=>{
    //console.log(props.drucks,props.druckd )
    if (pumpetype!==2) {
      if (props.drucks!==null && props.drucks!==''){
            setDruckManSaWert(props.drucks)
         //   druckManSaRef.current.value=props.drucks;
      }
      if (props.druckd!==null && props.druckd!==''){
            setDruckManDrWert(props.druckd)
           // druckManDrRef.current.value=props.druckd;
      }
      if (props.drehd!==null && props.drehd!==''){
              setDrehWert(props.drehd)
            //  drehRef.current.value=props.drehd;
      }
  
      if (props.strome!==null && props.strome!==''){
              setStromeWert(props.strome)
            //  stromaufnahmRef.current.value=props.strome;
      }

      setDruckPumpeVal();

    }
   
if (pumpetype===2) {
  if (props.stromn!==null && props.stromn!==''){
    setStromnWert(props.stromn)
   // stromaufnahmnRef.current.value=props.stromn;
  }
  
  if (props.stromnn!==null && props.stromnn!==''){
    setStromnnWert(props.stromnn)
    //stromaufnahmnnRef.current.value=props.stromnn;
  }

  if (props.druckud!==null && props.druckud!==''){
    setDruckudWert(props.druckud)
   // druckudRef.current.value=props.druckud;
  }

  setAnfangsdruckVal();
}


   // console.log('props')
   
  
  }, [])
 
  
  useEffect(()=>{
    // console.log(textValue)
   const timer=setTimeout(()=>{
     if (druckManDrWert=== druckManDrRef.current.value){
      
      setDruckPumpeVal()

     }
   }, 500);
   return ()=>{
     clearTimeout(timer);
   };
   
   },[druckManDrRef, druckManDrWert])

   
  useEffect(()=>{
    // console.log(textValue)
   const timer=setTimeout(()=>{
     if (druckManSaWert=== druckManSaRef.current.value){
      setDruckPumpeVal();
    /*
      let pumpelist=JSON.parse(authCtx.pumpelist);
      const pumpeid=authCtx.pumpeid;
      let updatedpumpe=pumpelist.filter(el=>el.lfdAuftragWartungsPumpeID===pumpeid)
         // updatedpumpe[0]['DruckS'+props.id]=Number(druckManDrWert);

          if (druckManSaWert!==null && druckManSaWert!==''){
            updatedpumpe[0]['DruckS'+props.id]=Number(druckManSaWert);
           
          } else {
            updatedpumpe[0]['DruckS'+props.id]='';
          }

          //updatedpumpe[0]['fkAuftragWartungsAusfuehrungID']=Number(authCtx.ausfuehrungid)
          updatedpumpe[0]['updated']=1;
       //   authCtx.addPumpelist(JSON.stringify(pumpelist))
       */
     }
   }, 500);
   return ()=>{
     clearTimeout(timer);
   };
   
   },[druckManSaWert, druckManSaRef])

   const setDruckPumpeVal=()=>{

    if (pumpetype!==2){
      if (druckManDrWert===null || druckManSaWert===null || druckManDrWert==='' || druckManSaWert===''){
        setDruckPumpeVorhWert('')
       // console.log('leer')
        return;
      }
        if (druckManDrWert!==null && druckManSaWert!==null && druckManDrWert!=='' && druckManSaWert!=='' ){
          setDruckPumpeVorhWert(Number(druckManDrWert)+Number(druckManSaWert))
        } 
    }
   
        
    
   }

   const setAnfangsdruckVal=()=>{
    if (druckudWert!==null && druckudWert!==null ){
      setAnfangsdruckWert(Number(druckudWert))
    } 
   }
   


    return (

        <Fragment>
        {pumpetype!==2 && (
            <tr>
                <td className="number">
                            <label>{props.id}</label>
                </td>
                <td className="Q">
                            <label>{props.q}</label> 
                </td>
                <td className="percent"> 
                            <label>{props.a}</label> 
                </td>
                <td className="green">
                            <label>{props.d}</label>
                </td>
                {pumpetype===2 && (
                  <td className="green">
                              <label>{props.pi}</label>
                  </td>
                )}
                <td className="yellow">
                    <input 
                        id={"dr"+props.id}
                        type="number"
                        value={druckManDrWert}
                        ref={druckManDrRef}
                        onChange={event=>setDruckManDrWert(event.target.value)}
                     />
                </td>
                <td className="yellow--1">
                            <input 
                                 id={"sa"+props.id}
                                 type="number"
                                 value={druckManSaWert}
                                 ref={druckManSaRef}
                                 onChange={event=>setDruckManSaWert(event.target.value)}
                            />
                </td>
                <td className="gray">
                        <input 
                          type="number"
                           disabled
                           id={"druck"+props.id}
                           value={druckPumpeVorhWert}
                             />
                </td>
                {pumpetype===1 && (          
                <td className="yellow--2">
                              <input 
                                  type="number"
                                  id={"strome"+props.id}
                                  value={stromeWert}
                                  ref={stromaufnahmRef}
                                  onChange={event=>setStromeWert(event.target.value)}
                                />
                 </td>)}
                 {pumpetype===3 && (          
                <td className="yellow--2">
                              <input 
                                  type="number"
                                  id={"drehd"+props.id}
                                  value={drehWert}
                                  ref={drehRef}
                                  onChange={event=>setDrehWert(event.target.value)}
                                />
                 </td>)}
          </tr>
        )} 
        {pumpetype===2 && (
            <tr>
                  <td className="number">
                                <label>{props.id}</label>
                  </td>
                <td className="Q">
                                <label> {props.q}</label>
                 </td>
                <td className="percent">
                                <label> {props.a}</label>
                </td>
                <td className="green">
                              <label>{props.d}</label>
                  </td>
                <td className="green">
                              <label>{props.pi}</label>
                  </td>
                <td className="yellow">
                            <input       
                                  type="number"
                                  id={"druckud"+props.id}
                                  value={druckudWert}
                                  ref={druckudRef}
                                  onChange={event=>setDruckudWert(event.target.value)}
                               />
                </td>
                <td className="gray">
                                    <input 
                                    disabled
                                    value={anfangsdruckWert}
                                    />
                </td>
                <td className="yellow--2">
                                   <input
                                         type="number"
                                         id={"stromn"+props.id}
                                         value={stromnWert}
                                         ref={stromaufnahmnRef}
                                         onChange={event=>setStromnWert(event.target.value)}
                                     /></td>
                 <td className="yellow--2">
                                     <input
                                        type="number"
                                        id={"stromnn"+props.id}
                                        value={stromnnWert}
                                        ref={stromaufnahmnnRef}
                                        onChange={event=>setStromnnWert(event.target.value)}
                                     />
                   </td>
            </tr>
        )} 
       
        </Fragment>
      );

}
export default PumpeForm;