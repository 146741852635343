
import React, { useState, useEffect, useContext, useCallback } from 'react';
import Header from '../component/Header';
import { useRef } from 'react';
import AuthContext from '../store/auth-context';
import { useNavigate } from "react-router-dom";
import SignatureCanvas from 'react-signature-canvas';
import userEvent from '@testing-library/user-event';
//import {GrDocumentPdf} from "react-icons";
//import AuftragContext, { AuftragContextProvider } from '../store/auftrag-context';
import $ from "jquery";


function Unterschrift() {
  const [URL, setURL] = useState(null);
  const [URL2, setURL2] = useState(null);
  const padRef = useRef(null);
  const pad2Ref = useRef(null);
  const checkRef = useRef();
  
  const nameInputRef = useRef();
  const [kundeName, setKundeName] = useState();
  const [canvas, setCanvas] = useState();
  const [mundlich, setMundlich] = useState(false);

  


  async function addSignHandler(data, url){
   
    const response=await fetch(url, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: { 'Content-Type': 'application/json' },
    });

    const respdata = await response.json();
    console.log(respdata)
    if(!response.ok){
      throw new Error('Something went wrong!');
    }

  }

  async function addUserHandler(data){
    /*
   let url='https://tbsmobile.de/save_username.php'; 
    const response=await fetch(url, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: { 
        'Content-Type': 'application/json' 
      },
    });

const respdata = await response.json();
console.log(respdata)
    if(!response.ok){
      throw new Error('Something went wrong!');
    }
console.log('addUserHandler')
*/
  }
  //const data2 = { "lfdAuftragWartungsAusfuehrungID": lfdAuftragWartungsAusfuehrungID, "Ansprechpartner": name.value };

  const backtoKunde=()=>{
    setPageName(
      {name: 'Unterschrift Kunde ', 
      link1: '/', 
      link2: '/',
      hide1: 'yes',
      hide2: 'no',
      }
      )
     
      padRef?.current?.clear();
      setURL(null);
      setKundeName(null);
  }

  const clientHasSigned=()=>{
    let statusid=Number(authCtx.ausfuehrungstatusid);
    if (pageName.name==='Unterschrift Kunde ') {
      var upd = false;
      let inputval = document.getElementById('Name');
      if (!inputval.value || inputval.value===null || inputval.value===''){
        alert('Bitte Name eingeben')
        return;
      }
  
      if (mundlich===false && URL===null){
        alert('Bitte unterschreiben')
        return;
      }
      console.log(URL)
     
  
        
        if (statusid>0){
          let url='https://tbsmobile.de/save_username.php';
          let data2={ "lfdAuftragWartungsAusfuehrungStatusID": statusid, "Ansprechpartner": kundeName, }
          console.log(data2)
          $.ajax({ type: 'POST', data: data2, url: url, async: false,
                success: function () {  upd = true; },
                error: function () { upd = false; }
                });
  
              if (upd === false){
                alert('Fehler update')
              }
  
          //addUserHandler(data2)
        }
  
        let data = {
          "img": URL,
          };
        let url='https://tbsmobile.de/sign_kunde.php';
        $.ajax({ type: 'POST', data: data, url: url, async: false,
                success: function () {  },
                error: function () {  }
            });
  
      
         // addSignHandler(data, url)
  
      setPageName(
          {name: 'Unterschrift Monteur ', 
          link1: '/', 
          link2: '/',
          hide1: 'no',
          hide2: 'no'})

      } else if(pageName.name==='Unterschrift Monteur '){

        if (URL2===null){
          alert('Bitte unterschreiben')
          return;
        }
        var ins = false;
        var pdf_file = false;
        var send_file = false;
        let data = {
          "img": URL2,
          };
        let url='https://tbsmobile.de/sign_monteur.php';
        $.ajax({ type: 'POST', data: data, url: url, async: false,
                success: function () {  ins=true  },
                error: function () { ins = false; }
            });

      
            if(ins){
              $.ajax({ type: 'POST', data: data, url: 'https://tbsmobile.de/pdf.php?id=' + statusid + '&output=f', async: false,
              success: function () {pdf_file = true}, error: function () { 
                console.log('error');
                pdf_file = false;
               }
              });
              if (pdf_file){
                $.ajax({type: 'POST', url: 'https://tbsmobile.de/email_sender.php?' + statusid, async: false,
                success: function () { send_file = true; }, error: function () { send_file = false; }
                });

                if (send_file) {
                  // window.localStorage.removeItem('checkliste'); 
                  // window.localStorage.removeItem('bereiche'); 
                   //window.localStorage.removeItem('fragen'); 
                  // window.localStorage.removeItem('bereicheBe');
                   alert("E-Mail mit Prüfbericht und Checkliste wurde verschickt.");
                   setURL(null)
                   setURL2(null)
                   setKundeName(null)
                    //delete data from localstorage
                    authCtx.delChecklistid();
                    authCtx.delBereichid();
                    authCtx.delAntworte();
                    authCtx.delPumpelist();
                    authCtx.delPumpeid();
                    authCtx.delFragenlist();
                    authCtx.delBereichelist();


                   navigate('/checklist')
                   //location.href = "index.html";
                   } else { alert("Es gibt ein Fehler"); }
              } else { alert("Es gibt ein Fehler!!!"); } 
              


            } else {
              alert("Es gibt kein Internetverbingdung");
            }

      //'E-Mail mit Prüfbericht und Checkliste wurde verschickt.

      }
    

  
  }


  const [pageName, setPageName] = useState( 
    {name: 'Unterschrift Kunde ', 
    link1: '/', 
    link2: '/',
    hide1: 'yes',
    hide2: 'no',
    }
    );
 
   
  const clearSignatureCanvas = useCallback(() => {
    if (pageName.name==='Unterschrift Kunde '){
    padRef?.current?.clear();
  
    } else {
      pad2Ref?.current?.clear();
    }
  }, []);
    
   
  

   

   
  const navigate = useNavigate();
  
  
  
  const [isLoading, setIsLoading]=useState(true);
  const [httpError, setHttpError]=useState();
  const authCtx = useContext(AuthContext);
 // const auftragCtx=useContext(AuftragContext);

  

  let userid=authCtx.userid;
 
  const clickDeleteHandler=(event)=>{
    event.preventDefault();
  
   
    clearSignatureCanvas();
    
    if (pageName.name==='Unterschrift Kunde '){
      setURL(null);
    } else {
      setURL2(null);
    }
  }


 




const handleSignatureEnd = () => {
  if (pageName.name==='Unterschrift Kunde '){
    setURL(padRef.current.toDataURL());
  } else {
    setURL2(pad2Ref.current.toDataURL());
  }
  
}



  
  
 //Weiter --> Name von Kunde==leer --> Bitte Name eingeben // Schließen

//className="signature" id="signature"
  return (

    <div>
     <Header onClick={clientHasSigned} onBack={backtoKunde} items={pageName} />
     <div className="wrapper">
     <section >
                        <div  data-theme="b" align="center" >
                                      {pageName.name==='Unterschrift Kunde ' && (
                                <div>
                                    <SignatureCanvas  ref={padRef}
                                         
                                             penColor='black'
                                              backgroundColor='white'
                                              canvasProps={{width: 700, height: 200}} 
                                               onEnd={handleSignatureEnd}
                                                />
                                  </div>
                                        )} 
                            {pageName.name!=='Unterschrift Kunde ' && (
                     
                                <div>
                                    <SignatureCanvas  ref={pad2Ref}
                                      
                                      penColor='black'
                                       backgroundColor='white'
                                       canvasProps={{width: 700, height: 200}} 
                                        onEnd={handleSignatureEnd}
                                         />
                                  </div> 
                              )}


            <div className="buttons">
                <button  type="button"  id="delete" onClick={clickDeleteHandler}  >
                                                    <p>Löschen</p><img src={require('../images/close.svg').default} alt='close' />
                </button>

            </div>
            
                           
                    {pageName.name==='Unterschrift Kunde ' && (      
                            <form className='Form--log'  action="" method="get" autoComplete="off" id="kundeform">
                                <div>
                                    <input type="checkbox" id="check" checked={mundlich}  ref={checkRef} onChange={event=>setMundlich(!mundlich)}/>
                                    <label id="mundlich">mündliche Freigabe durch</label>
                                 </div>
                      
                            
                
                                <div>
                                          <label>Name von Kunde</label>
                                          <input 
                                               type="text"
                                               id="Name" 
                                               name="Name"
                                               required
                                               value={kundeName}
                                               ref={nameInputRef}
                                               onChange={event=>setKundeName(nameInputRef.current.value)}/>
                              </div>
                            </form>
                      )}
                </div>
                  </section>
                  <div className="footer-wrapper">
                        <div className="buttons">
                  <button className="bnt-right" onClick={clientHasSigned} ><p>Weiter</p><img src={require('../images/weiter.svg').default} alt='aweiter' /></button>
                      </div>
          </div>
      </div>
                 
                  
                 
  
    </div>
  
  )
}

export default Unterschrift
