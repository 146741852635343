


const ChecklistItem=(props)=>{
   
const clickHandler=()=>{
    console.log('clickHandler');
    console.log(props)
}

    return (
        
   
        
        <div className='linkliste' onClick={props.onClick}>
            <div className='linktext'>
            {props.description} {props.type} 

            </div>
             <div className='linkbutton'>
            
             <img src={require('../images/weiter.svg').default} alt='weiter' />
            </div>
         </div>
   );
};

export default ChecklistItem;