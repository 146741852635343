
import React from 'react';
import { useRef, useState, useContext, useEffect } from 'react';
const Massnahmen=React.memo(props=>{
    const [textValue, setTextValue]=useState(props.items.massnahmen);
    const textInputRef=useRef();
    let text1='';
    let text2='';
    let text=props.children;
  // console.log(props.items)

    useEffect(()=>{
        // console.log(textValue)
       const timer=setTimeout(()=>{
         if (textValue=== textInputRef.current.value){
            let newRow={};
            newRow.id=props.items.id;
            newRow.text=textValue;
            newRow.geprueft=props.items.geprueft;
            props.onChangetext(newRow);
         }
       }, 500);
       return ()=>{
         clearTimeout(timer);
       };
       
       },[textInputRef, textValue])

    const modalActions= (
        <div className="message-modal__actions">
                   
            <button type="button" className="button" onClick={props.onClose}>
                    Abbrechnen
                </button>
            <button type="button" className="button" onClick={props.onAction}>
                    Speichern
                </button>
    </div>
        );

    const textIntputContent= (
        <React.Fragment>
            	    <div >
                        <textarea 
                            value={textValue} 
                            onChange={event=>setTextValue(event.target.value)}  
                            ref={textInputRef} 
                             />
                    </div>
                {modalActions}
    </React.Fragment>);


    return (
        <React.Fragment>
            <div className="backdrop" onClick={props.onClose}  />
            <div className="message-modal">
               <h3>{props.children}</h3>
                    
             {textIntputContent}

            </div>

        </React.Fragment>
      
        );

});

export default Massnahmen;
