import { Link } from 'react-router-dom'
import React, { useState, useEffect,useContext } from 'react';
import Header from '../component/Header'
import AusfuehrungItem from '../component/AusfuehrungItem';
import AuthContext from '../store/auth-context';
import { useNavigate } from "react-router-dom";
import {  Route, Navigate, BrowserRouter, Routes } from 'react-router-dom';
//import AuftragContext, { AuftragContextProvider } from '../store/auftrag-context';

import NodataMessage from '../component/NodataMessage';

function Auftraege() {
  const navigate = useNavigate();
  const[ausfuehrungList, setAusfuehrung]=useState();
  const [isLoading, setIsLoading]=useState(true);
  const [httpError, setHttpError]=useState();
  const [homeBtn, setHomeBtn] = useState({name:'Abmelden', path: '/'});
  const authCtx = useContext(AuthContext);
 // const auftragCtx=useContext(AuftragContext);
 const [pageName, setPageName] = useState( 
  {name: 'Liste Wartungsaufträge', 
  link1: '/', 
  link2: '/',
  hide1: 'yes',
  hide2: 'no'
}
  );


  const abmelden=()=>{
    authCtx.delAuftragid();
    authCtx.logout();
  }

  const clickItemHandler=(item)=>{
  //  console.log(item);
    authCtx.addAuftragid(item.auftragid);
   authCtx.addAusfuehrungid(item.id);
   //
   navigate('/checklist')
  
  }

  let userid=authCtx.userid;
 
  
    useEffect(()=> {
      const fetchItems=async()=>{
            setIsLoading(true);
            let url='https://tbsmobile.de/react-ausfuehrung.php?id='+userid
          const response=await fetch(url, {
      // 15 is User Identifier
      
            headers: {
              'Content-Type': 'application/json'
              // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            // redirect: 'follow', // manual, *follow, error
            // referrerPolicy: 'no-referrer',
        });
   
    if(!response.ok){
      throw new Error('Something went wrong!');
    }
    const responseData=await response.json();
//transform to array
    const loadedItems=[];

    for (const key in responseData){
    
          loadedItems.push({
            id: responseData[key].lfdAuftragWartungsAusfuehrungID,
            plz: responseData[key].bvPLZ,
            ort: responseData[key].bvOrt,
            description: responseData[key].AuftragsNr,
            bz: responseData[key].BauvorhabenZ1,
            date:responseData[key].Datum,
            auftragid:responseData[key].fkAuftragID,
           
      });
    }
    setAusfuehrung(loadedItems);
    setIsLoading(false);
//console.log(loadedItems);
  };


  fetchItems().catch(error=>{
        setIsLoading(false);
        setHttpError(error.message);
       });
    
 
  
  
}, []);

if (isLoading){
  return (
    <div>

        <Header items={pageName}/>

      
          <section >
                  <NodataMessage>Loading...</NodataMessage>
          </section>
    </div>
  
  );
}
  if (httpError) {
    return (
      <div>

       
          <Header items={pageName}/>

         
          <div className="wrapper">

          <section >
                <NodataMessage>{httpError}</NodataMessage>
          </section>
          </div>
      </div>
  
  );
  }
    
  if (ausfuehrungList.length===0){
    return (
      <div>

         <Header items={pageName}/>
          <div className="wrapper">
           <section >
              <NodataMessage>Keine Daten</NodataMessage>
           </section>
           </div>
    </div>
    );
   }


//nach anklicken eines Auftrags, frage ob Wartung begonnen werden soll? => Ja, weiter zu Seite 2


  return (

    <div>
        <Header items={pageName}/> 
        <div className="wrapper">
             <section >
  
              <div > 
      {
                  ausfuehrungList.map((item)=> (
                   
                  <AusfuehrungItem   onClick={clickItemHandler.bind(null,item)}
                          key={item.id}
                          id={item.id}
                         
                         description={item.description}
                          bz={item.bz}
                        date={item.date}
                        plz={item.plz}
                        ort={item.ort}
     
                  />
                
                  ))
                }
 
      
      </div>
                  </section>
     
                  {!authCtx.isLoggedIn && <Navigate to="/" />}
                  </div>
    </div>
  
  )
}

export default Auftraege
